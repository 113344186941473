import React, { useContext, useEffect, useState } from 'react'
import { MegaMenu } from 'primereact/megamenu'
import originsManganegment from '../../../assets/images/ORIGINS-MANAGEMENT.png'
import originsDirect from '../../../assets/images/ORIGINS-DIRECT.png'
import { AuthenticationContext } from '../../../Auth/authentication.context'
import { useNavigate } from 'react-router-dom'
const Systems = () => {
  const [system, setSystem] = useState()
  const navigation = useNavigate()
  const { setModule, user } = useContext(AuthenticationContext)



  useEffect(() => {
    const moduleFromLocalStorage = localStorage.getItem('module')
    if (moduleFromLocalStorage) {
      setModule(moduleFromLocalStorage)
    } else if (user) {
      const originsDirectModule = user.pharmacyModules.find(
        (module) => module.name === 'Origins Direct'
      )
      if (originsDirectModule) {
        setModule(originsDirectModule.name)
      } else {
        setModule(user?.pharmacyModules[0]?.name)
      }
    }
  }, [user])

  const itemRenderer = (item) => {
    const originsDirectModule = user?.pharmacyModules?.find(
      (module) => item.module === module.name
    )
    return (
      <>
        {originsDirectModule !== undefined && (
          <div
            className='flex flex-column align-items-start py-3'
            style={{
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = 'rgb(240, 238, 238)')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = 'initial')
            }
            onClick={() => {
              localStorage.setItem('module', item.module)
              setModule(item.module)
              setSystem(item.module)
              navigation('/')
            }}
          >
            <img
              alt='megamenu-demo'
              src={item.image}
              style={{ height: '25px', marginLeft: '20px' }}
            />
          </div>
        )}
      </>
    )
  }

  const items = [
    {
      label: 'Switch Apps',
      icon: 'pi pi-system',
      items: [
        [
          {
            label: '',
            items: [
              {
                key: '23',
                image: originsDirect,
                module: 'Origins Direct',
                template: itemRenderer,
              },
              {
                key: '2321',
                image: originsManganegment,
                module: 'Origins Management',
                template: itemRenderer,
              },
            ],
          },
        ],
      ],
    },
  ]

  return (
    <div
      style={{ backgroundColor: 'white', borderRadius: '20px', padding: '0px' }}
    >
      <MegaMenu
        model={items}
        style={{ padding: '0px' }}
        submenuIcon={() => (
          <div style={{ marginLeft: '10px', opacity: '50%' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM17.5 8.25C17.5 7.83579 17.1642 7.5 16.75 7.5C16.3358 7.5 16 7.83579 16 8.25V8.99949C15.0878 7.78533 13.6356 7 12 7C10.4734 7 9.10595 7.68513 8.18998 8.76203C7.92161 9.07755 7.95983 9.55088 8.27535 9.81925C8.59087 10.0876 9.0642 10.0494 9.33257 9.73388C9.97572 8.97773 10.9317 8.5 12 8.5C13.3962 8.5 14.6015 9.31753 15.1632 10.5H13.75C13.3358 10.5 13 10.8358 13 11.25C13 11.6642 13.3358 12 13.75 12H16.75C17.1642 12 17.5 11.6642 17.5 11.25V8.25ZM7.25 16.5C6.83579 16.5 6.5 16.1642 6.5 15.75V12.75C6.5 12.3358 6.83579 12 7.25 12H10.25C10.6642 12 11 12.3358 11 12.75C11 13.1642 10.6642 13.5 10.25 13.5H8.83683C9.39857 14.6825 10.6038 15.5 12 15.5C13.0679 15.5 14.0235 15.0227 14.6666 14.2671C14.9351 13.9517 15.4085 13.9136 15.7239 14.1821C16.0393 14.4506 16.0774 14.9239 15.8089 15.2393C14.8929 16.3155 13.526 17 12 17C10.3644 17 8.91223 16.2146 8 15.0005V15.75C8 16.1642 7.66421 16.5 7.25 16.5Z'
                fill='#212121'
              />
            </svg>
          </div>
        )}
      />
    </div>
  )
}

export default Systems
