import React, { useEffect, useState } from 'react'
import MainContentLayout from '../Layout/MainContentLayout'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import InviteEditManufacturersUsers from './Modals/InviteEditManufacturersUsers'
import server from '../../server'
import DeleteModal from '../shared/DeleteModal'
const ManufacturersUsers = () => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState('')
  const [lazyState, setLazyState] = useState({ rows: 10, page: 0, first: 0 })
  const [usersData, setUserData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [singleUserData, setSingleUserData] = useState({})
  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [csvLoading, setcsvLoading] = useState(false)


  let requestParams =
    `?page=${Number(lazyState?.page) + 1}` +
    `&limit=${lazyState.rows}` +
    `&role=manufacturer` +
    `${lazyState?.sortField ? '&sortByField=' + lazyState.sortField : ''}` +
    `${lazyState?.sortOrder ? '&sortByOrder=' + lazyState.sortOrder : ''}` +
    `${
      lazyState?.filters?.displayName &&
      lazyState.filters.displayName.constraints[0].value
        ? '&displayName=' + lazyState.filters.displayName.constraints[0].value
        : ''
    }` +
    `${
      lazyState?.filters?.email && lazyState.filters.email.constraints[0].value
        ? '&email=' + lazyState.filters.email.constraints[0].value
        : ''
    }` +
    `${
      lazyState?.filters?.manufacturer &&
      lazyState.filters.manufacturer.constraints[0].value
        ? '&manufacturer=' + lazyState.filters.manufacturer.constraints[0].value
        : ''
    }`

  const getData = () => {
    setTableLoading(true)
    server
      .get(`/users/get-users${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setUserData(response.data.users)
        setTotalRecords(response.data.totalCount)
        setTableLoading(false)
      })
      .catch((error) => {
        setTableLoading(false)
      })
  }
  const initLazyState = () => {
    setLazyState({ rows: 10, page: 0, first: 0 })
  }
  useEffect(() => {
    checkClearBtn()
    getData()
  }, [lazyState])
  const exportCSV = () => {
    setcsvLoading(true)
    server
      .get(`/users/export/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blobData)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'manufacturers users.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        setcsvLoading(false)
      })
      .catch((error) => {
        setTableLoading(false)

      })
  }
  const checkClearBtn = () => {
    if (lazyState?.filters) {
      setClearBtnDisabled(false)
    } else {
      setClearBtnDisabled(true)
    }
  }
  const topLeftToolbarTemplate = () => {
    return (
      <div className='d-flex'>
        <Button
          type='button'
          icon='pi pi-filter-slash'
          label='Clear'
          outlined
          onClick={initLazyState}
          disabled={clearBtnDisabled}
        />
      </div>
    )
  }

  const topRightToolbarTemplate = () => {
    return (
      <div className='d-flex'>
        <Button
          label='Invite Manufacturer User'
          icon='pi pi-plus'
          severity='success'
          onClick={() => {
            setModalType('add')
            setModalVisible(true)
          }}
        />
      </div>
    )
  }

  const onPage = (event) => {
    setLazyState(event)
  }

  const onSort = (event) => {
    setLazyState(event)
  }

  const onFilter = (event) => {
    setLazyState(event)
  }

  const manufacturersBody = (rowData) => {
    let manufacturersNames = rowData?.manufacturers?.map((itm, index) => {
      return (
        <h4 className='m-0' key={index}>
          <span className='badge bg-primary  rounded-pill text-light py-2 px-3 fw-bold '>
            {itm?.name}
          </span>
        </h4>
      )
    })
    return <div className='d-flex gap-2 flex-wrap '>{manufacturersNames}</div>
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex justify-content-center gap-2'>
          <Button
            icon='pi pi-pencil'
            rounded
            outlined
            className='mr-2'
            onClick={() => {
              setModalType('edit')
              setSingleUserData(rowData)
              setModalVisible(true)
            }}
          />

          <Button
            icon='pi pi-trash'
            rounded
            outlined
            severity='danger'
            onClick={() => {
              setDeleteVisible(true)
              setSingleUserData(rowData)
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  const hideDeleteUser = () => {
    setDeleteVisible(false)
  }

  const handleDeleteUser = () => {
    setLoading(true)
    server
      .delete(`/users/${singleUserData?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setLoading(false)
        setDeleteVisible(false)
        getData()
      })
      .catch((error) => {
        setLoading(false)
  
      })
  }

  const deleteFooter = (
    <React.Fragment>
      <Button label='No' icon='pi pi-times' outlined onClick={hideDeleteUser} />
      <Button
        label='Yes'
        icon='pi pi-check'
        severity='danger'
        loading={loading}
        onClick={handleDeleteUser}
      />
    </React.Fragment>
  )
  return (
    <>
      <MainContentLayout
        dt={exportCSV}
        handleExport={true}
        loading={csvLoading}
        title='Manufacturer Users'
      >
        <Toolbar
          className='mb-2'
          left={topLeftToolbarTemplate}
          right={topRightToolbarTemplate}
        />

        <DataTable
          value={usersData}
          dataKey='id'
          paginator
          lazy
          showGridlines
          removableSort
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={tableLoading}
          globalFilterFields={['name', 'email', 'manufacturer']}
          selectionMode='single'
          emptyMessage='No users found.'
          filters={lazyState?.filters}
          sortField={lazyState?.sortField}
          sortOrder={lazyState?.sortOrder}
          rows={lazyState?.rows}
          first={lazyState?.first}
          onPage={onPage}
          onSort={onSort}
          onFilter={onFilter}
        >
          <Column
            field='displayName'
            header='Full Name'
            sortable
            style={{ minWidth: '16rem' }}
            filterPlaceholder='Filter by full name'
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field='email'
            header='Email'
            sortable
            style={{ minWidth: '16rem' }}
            filterPlaceholder='Filter by email'
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field='manufacturer'
            header='Manufacturers'
            sortable
            style={{ minWidth: '16rem' }}
            filterPlaceholder='Filter by manufacturer'
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            body={manufacturersBody}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: '12rem' }}
          ></Column>
        </DataTable>
      </MainContentLayout>
      <InviteEditManufacturersUsers
        visible={isModalVisible}
        setVisible={setModalVisible}
        getData={getData}
        type={modalType}
        userData={singleUserData}
      />
      <DeleteModal
        state={deleteVisible}
        footer={deleteFooter}
        onHide={hideDeleteUser}
        deletedItem={true}
        name={singleUserData?.displayName}
      />
    </>
  )
}

export default ManufacturersUsers
