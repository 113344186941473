import React, { useState, useContext, useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import server from '../../server'
import '../../scss/custom/components/pharmacy-profile.scss'
import { Container, Card, CardBody, Label, Spinner } from 'reactstrap'
import { AuthenticationContext } from '../../Auth/authentication.context'
import { useLocation, useParams } from 'react-router-dom'
import { ToastPopUp } from '../shared/utils'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { DropDownTemplate, InputFieldTemplate } from '../shared/FilterTemplates'
import PharmacyProfileDetails from './PharmacyProfileDetails'
import BackButton from '../shared/backButton'
import DeleteModal from '../shared/DeleteModal'
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
const PharmacyProfile = () => {
  document.title = 'Profile | Origins Direct'
  const { toastStatus } = useContext(AuthenticationContext)

  const toast = useRef(null)
  const { pharmacyId } = useParams()
  const { state } = useLocation()

  const [user, setUser] = useState()
  const [manufacturerTableData, setManufacturerTableData] = useState()
  const [tableLoading, setTableLoading] = useState()
  const [pageLoading, setPageLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [addedSupplier, setAddedSupplier] = useState({})
  const [addedManufacturer, setAddedManufacturer] = useState({})
  const [moleculeDialog, setMoleculeDialog] = useState(false)
  const [manufacturerDialog, setManufacturerDialog] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [Manufacturers, setManufacturers] = useState([])
  const [manufacturerRow, setManufacturerRow] = useState({})
  const [editManufacturerRow, setEditManufacturerRow] = useState(false)
  const [selectedManufacturer, setSelectedManufacturer] = useState(null) // Store the selected manufacturer

  const [originsDirectModule, setOriginsDirectModule] = useState('')
  const [originsManagementModule, setOriginsManagementModule] = useState('')

  const [deleteVisible, setDeleteVisible] = useState(false)
  const [reloadData, setReloadData] = useState(false)
  const suppliersList = [{ name: 'K+F', code: 1 }]
  const userId = state?.id

  const [isRepModalVisible, setIsRepModalVisible] = useState(false)

  const formikRef = useRef(null)
  const handleOpenDialog = () => {
    setManufacturerDialog(true)
    if (formikRef.current) {
      formikRef.current.submitForm() // Automatically trigger form submission
    }
  }

  const validationSchemaAdd = Yup.object().shape({
    manufacturerId: Yup.string().required('Manufacturer name is required'),
    manufacturerCode: Yup.string().required('Manufacturer code is required'),
    reps: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Rep name is required'),
          email: Yup.string()
            .email('Invalid email format')
            .required('Rep email is required'),
        })
      )
      .min(1, 'At least one rep is required'),
  })
  const validationSchemaEdit = Yup.object().shape({
    manufacturerCode: Yup.string().required('Manufacturer code is required'),
    reps: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Rep name is required'),
          email: Yup.string()
            .email('Invalid email format')
            .required('Rep email is required'),
        })
      )
      .min(1, 'At least one rep is required'),
  })
  const handleRepSubmit = async (values) => {
    try {
      if (editManufacturerRow) {
        // Edit mode
        await editManufacturer(values)
      } else {
        // Add mode
        await addManufacturer(values)
      }
      setManufacturerDialog(false) // Close the dialog after submission
      getManufacturers() // Refresh the list
    } catch (error) {
      console.error('Error submitting form:', error)
      // Optionally, show error feedback to the user
    }
  }

  useEffect(() => {
    const originsDirect = user?.pharmacyModules?.find(
      (module) => module.name === 'Origins Direct'
    )
    if (originsDirect) {
      setOriginsDirectModule(originsDirect.name)
    }

    const originsManagement = user?.pharmacyModules?.find(
      (module) => module.name === 'Origins Management'
    )
    if (originsManagement) {
      setOriginsManagementModule(originsManagement.name)
    }
  }, [user])

  const addSupplier = () => {
    if (addedSupplier?.supplier?.code && addedSupplier?.customerAccountCode) {
      server
        .patch(
          `/admins/pharmacy/${parseInt(pharmacyId)}`,
          {
            supplierId: addedSupplier.supplier.code,
            customerAccountCode: addedSupplier.customerAccountCode,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        )
        .then((response) => {
          ToastPopUp(
            toast,
            'success',
            'Account Updated Successfully',
            `supplier account with id ${addedSupplier.customerAccountCode} for ${addedSupplier.supplier.name} is successfully attached to current pharmacy`
          )
          getUserData()
          hideDialog()
        })
        .catch((e) => {
          if (e.response.status === 400) {
            ToastPopUp(
              toast,
              'error',
              'Error Adding Supplier',
              e.response.data.message
            )
          }
        })
    } else {
      setSubmitted(true)
    }
  }

  const addManufacturer = async (values) => {
    server
      .post('manufacturerPharmacyCode', values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        getManufacturerDataTable()
      })
      .catch((error) => {
        console.error('Error activating manufacturer:', error)
      })
  }

  const editManufacturer = async (values) => {
    // deleting data for edit manufacturer endpoint
    delete values['manufacturerId']
    delete values['pharmacyId']

    server
      .patch(`/manufacturerPharmacyCode/${manufacturerRow?.id}`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        getManufacturerDataTable()
      })
      .catch((error) => {
        console.error('Error activating manufacturer:', error)
      })
  }

  const getUserData = () => {
    setTableLoading(true)
    server
      .get(`/users/get-users?id=${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const userData = response.data.users[0]
        setUser(userData)
        if (
          !userData?.pharmacyModules?.find(
            (module) => module?.name === 'Origins Management'
          )
        ) {
          setOriginsManagementModule(false)
        }

        if (
          !userData?.pharmacyModules?.find(
            (module) => module?.name === 'Origins Direct'
          )
        ) {
          setOriginsDirectModule(false)
        }
        setTableLoading(false)
        setPageLoading(false)
      })
      .catch((error) => {

        setTableLoading(false)
      })
  }
 

  const getManufacturerDataTable = () => {
    setTableLoading(true)
    server
      .get(`/manufacturerPharmacyCode/${pharmacyId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturerData = response?.data?.Manufacturers
        setManufacturerTableData(manufacturerData)

        setTableLoading(false)
        setPageLoading(false)
      })
      .catch((error) => {
      
        setTableLoading(false)
      })
  }

  const getManufacturers = () => {
    setTableLoading(true)
    server
      // .get(`/manufacturers/?ordable=true`, {
      .get(`/manufacturers/getOnlyAvailableManufacturers/${pharmacyId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturers = response?.data?.manufacturers
        setTableLoading(false)
        setManufacturers(manufacturers)
      })
      .catch((error) => {
        setTableLoading(false)
   
      })
  }

  const handleDeleteManufacturer = () => {
    setLoading(true)
    server
      .delete(`/manufacturerPharmacyCode/${manufacturerRow?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setLoading(false)
        setDeleteVisible(false)
        getManufacturerDataTable()
        getUserData()
      })
      .catch((error) => {
        setLoading(false)

      })
  }

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className={'text-capitalize'}>{rowData.status}</span>
      </>
    )
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex justify-content-center gap-2'>
          <Button
            icon='pi pi-pencil'
            rounded
            outlined
            className='mr-2'
            onClick={() => {
              setManufacturerRow(rowData)

              setEditManufacturerRow(true)
              setManufacturerDialog(true)
            }}
          />

          <Button
            icon='pi pi-trash'
            rounded
            outlined
            severity='danger'
            onClick={() => {
              setManufacturerRow(rowData)
              setDeleteVisible(true)
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  const hideDeleteManufacturer = () => {
    setDeleteVisible(false)
  }

  const deleteManufacturerFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        outlined
        onClick={hideDeleteManufacturer}
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        severity='danger'
        loading={loading}
        onClick={handleDeleteManufacturer}
      />
    </React.Fragment>
  )

  useEffect(() => {
    if (toastStatus.toastStatus !== undefined) {
      ToastPopUp(toast, toastStatus?.toastStatus, toastStatus?.msg)
    }
  }, [toastStatus])

  useEffect(() => {
    getUserData()
    getManufacturers()
    getManufacturerDataTable()
  }, [])

  const hideDialog = () => {
    setSubmitted(false)
    setMoleculeDialog(false)
  }
  const hideManufacturer = () => {
    setManufacturerDialog(false)
  }

  const moleculeDialgeFooter = (
    <React.Fragment>
      <Button label='Cancel' icon='pi pi-times' outlined onClick={hideDialog} />
      <Button label='Add Supplier' icon='pi pi-check' onClick={addSupplier} />
    </React.Fragment>
  )
  const ManufacturerDialgeFooter = (
    <React.Fragment>
      <Button
        label='Cancel'
        icon='pi pi-times'
        outlined
        onClick={hideManufacturer}
      />
      <Button
        label={!editManufacturerRow ? 'Add Manufacturer' : 'Edit Manufacturer'}
        icon='pi pi-check'
        onClick={handleOpenDialog}
        // {!editManufacturerRow ? addManufacturer : EditManufacturer}
        loading={loading}
      />
    </React.Fragment>
  )

  const openNew = () => {
    setAddedSupplier(null)
    setSubmitted(false)
    setMoleculeDialog(true)
  }

  const topRightToolbarTemplate = () => {
    return (
      <div className='d-flex gap-2'>
        <Button
          label='New Supplier'
          icon='pi pi-plus'
          severity='success'
          onClick={openNew}
        />
      </div>
    )
  }
  const openManufacturer = () => {
    setManufacturerDialog(true)
  }

  const ManufacturerToolbarTemplate = () => {
    return (
      <div className='d-flex gap-2'>
        <Button
          label='New Manufacturer'
          icon='pi pi-plus'
          severity='success'
          onClick={() => {
            openManufacturer()
            setEditManufacturerRow(false)
          }}
        />
      </div>
    )
  }
  return (
    <React.Fragment>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>
      <div className='page-content'>
        {pageLoading ? (
          <div
            className='d-flex justify-content-center align-items-center '
            style={{ height: '80vh' }}
          >
            <Spinner color='primary' />
          </div>
        ) : (
          <Container fluid>
            <BackButton />
            <PharmacyProfileDetails
              user={user}
              reloadData={() => getUserData()}
              loading={loading}
            />
            {originsManagementModule && (
              <Card>
                <CardBody>
                  <Toolbar className='mb-2' right={topRightToolbarTemplate} />
                  <DataTable
                    value={user?.accounts}
                    dataKey='supplierId'
                    removableSort
                    loading={tableLoading}
                    selectionMode='single'
                    emptyMessage='No Suppliers found.'
                  >
                    <Column
                      field='supplierName'
                      header='Supplier Name'
                      sortable
                    />
                    <Column
                      field='customerAccountCode'
                      header='Customer Account Code'
                      sortable
                    />
                  </DataTable>

                  <Dialog
                    visible={moleculeDialog}
                    style={{ width: '32rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    header='Add Supplier'
                    modal
                    className='p-fluid'
                    footer={moleculeDialgeFooter}
                    onHide={hideDialog}
                  >
                    <div className='field mt-4'>
                      <label htmlFor='supplierName' className='font-bold'>
                        Supplier Name
                      </label>
                      <DropDownTemplate
                        id='supplierName'
                        options={suppliersList}
                        state={addedSupplier?.supplier}
                        stateHandler={(e) =>
                          setAddedSupplier((prevState) => {
                            return { ...prevState, supplier: e.value }
                          })
                        }
                      />
                      {submitted && !addedSupplier?.supplier && (
                        <small className='p-error'>
                          Supplier name is required.
                        </small>
                      )}
                    </div>
                    {
                      <div className='field mt-4'>
                        <label htmlFor='customerCode' className='font-bold'>
                          Customer Account Code
                        </label>
                        <InputFieldTemplate
                          id='customerCode'
                          state={
                            addedSupplier?.customerAccountCode
                              ? addedSupplier?.customerAccountCode
                              : ''
                          }
                          stateHandler={(e) =>
                            setAddedSupplier((prevState) => {
                              return { ...prevState, customerAccountCode: e }
                            })
                          }
                          placeholder='Customer account code'
                          required
                        />
                        {submitted && !addedSupplier?.customerAccountCode && (
                          <small className='p-error'>
                            Customer account code is required.
                          </small>
                        )}
                      </div>
                    }
                  </Dialog>
                </CardBody>
              </Card>
            )}

            {/* ==================================================================================================================================================== */}
            {originsDirectModule && (
              <Card>
                <CardBody>
                  <Toolbar
                    className='mb-2'
                    right={ManufacturerToolbarTemplate}
                  />
                  <DataTable
                    value={manufacturerTableData}
                    dataKey='id'
                    removableSort
                    loading={tableLoading}
                    selectionMode='single'
                    emptyMessage='No Manufacturer found.'
                  >
                    <Column
                      field='manufacturer.name'
                      header='Manufacturer Name'
                      sortable
                    />
                    <Column
                      field='manufacturerCode'
                      header='Manufacturer Account Code'
                      sortable
                    />
                    <Column
                      field='status'
                      header='Status'
                      body={statusBodyTemplate}
                    />
                    <Column
                      body={actionBodyTemplate}
                      exportable={false}
                      style={{ minWidth: '12rem' }}
                    ></Column>
                  </DataTable>
                  <Dialog
                    visible={manufacturerDialog}
                    style={{ width: '32rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    header={
                      !editManufacturerRow
                        ? 'Add Manufacturer'
                        : 'Edit Manufacturer'
                    }
                    modal
                    className='p-fluid'
                    footer={ManufacturerDialgeFooter}
                    onHide={hideManufacturer}
                  >
                    {/* Manufacturer Name Field */}

                    {/* Manufacturer Code and Reps Fields */}
                    <div className='field mt-4'>
                      <Formik
                        innerRef={formikRef}
                        enableReinitialize={true}
                        initialValues={{
                          manufacturerId: editManufacturerRow
                            ? manufacturerRow?.manufacturerId
                            : '',
                          status: editManufacturerRow
                            ? manufacturerRow?.status
                            : '',
                          pharmacyId: editManufacturerRow
                            ? manufacturerRow?.pharmacyId
                            : pharmacyId,
                          manufacturerCode: editManufacturerRow
                            ? manufacturerRow?.manufacturerCode
                            : '',
                          reps: editManufacturerRow
                            ? manufacturerRow?.reps
                            : [{ name: '', email: '' }],
                        }}
                        validationSchema={
                          !editManufacturerRow
                            ? validationSchemaAdd
                            : validationSchemaEdit
                        }
                        onSubmit={(values) => {
                          handleRepSubmit(values)
                        }}
                      >
                        {({ values, handleSubmit, setFieldValue }) => (
                          <Form onSubmit={handleSubmit}>
                            {/* Manufacturer Dropdown */}

                            {!editManufacturerRow && (
                              <div className='field mt-4'>
                                <label
                                  htmlFor='manufacturersNames'
                                  className='font-bold'
                                >
                                  Manufacturer Name
                                </label>
                                <Field
                                  as='select'
                                  name='manufacturerId'
                                  className='form-control'
                                  value={values.manufacturerId.id}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'manufacturerId',
                                      e.target.value
                                    )
                                  }}
                                >
                                  <option
                                    value=''
                                    label='Select Manufacturer'
                                  />
                                  {Manufacturers.map((manufacturer) => (
                                    <option
                                      key={manufacturer.id}
                                      value={manufacturer.id}
                                      label={manufacturer.name}
                                    />
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name='manufacturerId'
                                  component='small'
                                  className='p-error'
                                />
                              </div>
                            )}

                            <div className='field mt-4'>
                              <label htmlFor='status' className='font-bold'>
                                status
                              </label>
                              <Field
                                as='select'
                                name='status'
                                className='form-control'
                                value={values.status}
                                onChange={(e) => {
                                  setFieldValue('status', e.target.value)
                                }}
                              >
                                <option value='' label='Select Status' />

                                <option
                                  key={'active'}
                                  value={'active'}
                                  label={'Active'}
                                />
                                <option
                                  key={'pending'}
                                  value={'pending'}
                                  label={'Pending'}
                                />
                              </Field>
                              <ErrorMessage
                                name='status'
                                component='small'
                                className='p-error'
                              />
                            </div>

                            {/* Manufacturer Account Code Field */}
                            <div className='field mt-4'>
                              <label
                                htmlFor='manufactureCode'
                                className='font-bold'
                              >
                                Manufacturer Account Code
                              </label>
                              <Field
                                name='manufacturerCode'
                                placeholder='Manufacturer account code'
                                className='form-control'
                                value={values.manufacturerCode}
                              />
                              <ErrorMessage
                                name='manufacturerCode'
                                component='small'
                                className='p-error'
                              />
                            </div>

                            {/* Rep Details (Using FieldArray) */}
                            <FieldArray name='reps'>
                              {({ push, remove }) => (
                                <>
                                  <div className='form-group col-auto d-flex justify-content-end'>
                                    <button
                                      type='button'
                                      className='btn btn-dark border-0 mt-3 mb-2 d-flex gap-2 align-items-center'
                                      id='addFieldButton'
                                      onClick={() =>
                                        push({ name: '', email: '' })
                                      }
                                    >
                                      <span className='fw-light lead fs-6'>
                                        Add Rep
                                      </span>
                                      <span className='mdi mdi-plus'></span>
                                    </button>
                                  </div>

                                  {/* Render each rep */}
                                 
                                  {values.reps.map((rep, index) => (
                                    <div
                                      className='form-row d-flex align-items-start gap-2 mb-3'
                                      key={index}
                                    >
                                      <div className='form-group w-100'>
                                        <label htmlFor={`reps.${index}.name`}>
                                          Name of Rep {index + 1}
                                        </label>
                                        <Field
                                          type='text'
                                          className='form-control'
                                          id={`reps.${index}.name`}
                                          name={`reps.${index}.name`}
                                          placeholder='Enter Name'
                                          value={values?.reps[index]?.name}
                                        />
                                        {/* Add a container for validation messages with a fixed height */}
                                        <div style={{ minHeight: '20px' }}>
                                          <ErrorMessage
                                            name={`reps.${index}.name`}
                                            component='small'
                                            className='text-danger'
                                          />
                                        </div>
                                      </div>

                                      <div className='form-group w-100'>
                                        <label htmlFor={`reps.${index}.email`}>
                                          Email of Rep {index + 1}
                                        </label>
                                        <Field
                                          type='email'
                                          className='form-control'
                                          id={`reps.${index}.email`}
                                          name={`reps.${index}.email`}
                                          placeholder='Enter Email'
                                          value={values?.reps[index]?.email}
                                        />
                                        {/* Add a container for validation messages with a fixed height */}
                                        <div style={{ minHeight: '20px' }}>
                                          <ErrorMessage
                                            name={`reps.${index}.email`}
                                            component='small'
                                            className='text-danger'
                                          />
                                        </div>
                                      </div>

                                      {values.reps.length > 1 && (
                                        <div className='form-group align-self-center '
                                        style={{marginTop:"10px"}}>
                                          <button
                                            style={{ fontSize: '20px' }}
                                            type='button'
                                            className='text-danger bg-transparent border-0 '
                                            onClick={() => remove(index)}
                                          >
                                            <span className='mdi mdi-trash-can'></span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </>
                              )}
                            </FieldArray>

                            {/* Submit Button */}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Dialog>
                </CardBody>
              </Card>
            )}
          </Container>
        )}
      </div>
      <DeleteModal
        state={deleteVisible}
        footer={deleteManufacturerFooter}
        onHide={hideDeleteManufacturer}
        deletedItem={true}
        name={`${manufacturerRow?.manufacturer?.name}`}
      />
    </React.Fragment>
  )
}

export default PharmacyProfile
