import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Row } from 'primereact/row'
import { ColumnGroup } from 'primereact/columngroup'

import MainContentLayout from '../../Layout/MainContentLayout'
import { formatCurrency, formatDate } from '../../shared/utils'
import server from '../../../server'
import {
  DateFilterTemplate,
  DropDownTemplate,
  InputFieldTemplate,
  MinMaxTemplate,
} from '../../shared/FilterTemplates'

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css'
//core
import 'primereact/resources/primereact.min.css'
//icons
import 'primeicons/primeicons.css'

const OriginsDirectOrders = () => {
  const dt = useRef()

  const [searchParams, setSearchParams] = useSearchParams()

  const currentDate = new Date()

  const [orders, setOrders] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0)
  const [csvLoading, setcsvLoading] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [lazyState, setLazyState] = useState({
    rows: 10,
    page: 0,
    first: 0,
    sortField: '',
    sortOrder: '',
    filters: {
      'supplier.name': { value: '' },
      supplierInvoiceNo: { value: '' },
      totalInvoicePa: { value: '' },
      totalInvoiceAmount: { value: '' },
      totalInvoicePaPercentage: { value: '' },
      status: { value: '' },
      orderDate: {
        value:
          Number(searchParams.get('today')) === 1
            ? [
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate() - 1
                ),
                currentDate,
              ]
            : '',
      },
    },
  })

  const navigate = useNavigate()

  const initLazyState = () => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: '',
      sortOrder: '',
      filters: {
        'supplier.name': { value: '' },
        supplierInvoiceNo: { value: '' },
        status: { value: '' },
        totalInvoicePa: { value: '' },
        totalInvoiceAmount: { value: '' },
        totalInvoicePaPercentage: { value: '' },
        orderDate: {
          value:
            Number(searchParams.get('today')) === 1
              ? [
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() - 1
                  ),
                  currentDate,
                ]
              : '',
        },
      },
    }))
  }

  const onRowSelect = (event) => {
    const order = orders.find(
      (item) => item.supplierInvoiceNo === event.data.supplierInvoiceNo
    )
    navigate(`${event.data.supplierOrderNo}`, { state: order })
  }

  const getData = () => {
    let startDate
    let endDate
    const dates = lazyState?.filters?.orderDate.value
    const totalAmount = lazyState?.filters?.totalInvoiceAmount.value
    const supplier = lazyState?.filters?.['supplier.name'].value
    const paAmount = lazyState?.filters?.totalInvoicePa.value
    const orderNumber = lazyState?.filters?.supplierInvoiceNo.value
    const status = lazyState?.filters?.status.value

    if (dates) {
      startDate = dates[0]
      endDate = dates[1] ? dates[1] : currentDate
    }
    const params = new URLSearchParams()

    // Add pagination params
    params.append('page', lazyState.page + 1)
    params.append('limit', lazyState.rows)

    if (totalAmount?.min)
      params.append('greaterThanTotalAmount', totalAmount.min)
    if (totalAmount?.max) params.append('lessThanTotalAmount', totalAmount.max)
    if (dates) {
      if (startDate) params.append('startDate', formatDate(startDate))
      if (endDate) params.append('endDate', formatDate(endDate))
    }
    if (supplier) params.append('manufacturerName', supplier)
    if (orderNumber) params.append('orderNo', orderNumber)
    if (status) params.append('status', status)
    if (lazyState?.sortField) params.append('sortByField', lazyState.sortField)
    if (lazyState?.sortOrder) params.append('sortByOrder', lazyState.sortOrder)

    const requestParams = `?${params.toString()}`

    setTableLoading(true)

    server
      .get(`/invoicesHeaders/pharmacy${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'x-system-name': 'Origins Direct',
        },
      })
      .then((response) => {
        const ordersData = response.data.invoicesHeaders
        setOrders(ordersData)
        setTotalRecords(response.data.totalCount)
        setTableLoading(false)
      })
      .catch((error) => {
        
        setTableLoading(false)
        // setError(error.response.data.message)
      })
  }
  const exportCSV = () => {
    let startDate
    let endDate
    const dates = lazyState?.filters?.orderDate.value
    const totalAmount = lazyState?.filters?.totalInvoiceAmount.value
    const supplier = lazyState?.filters?.['supplier.name'].value
    const paAmount = lazyState?.filters?.totalInvoicePa.value
    const orderNumber = lazyState?.filters?.supplierInvoiceNo.value
    const status = lazyState?.filters?.status.value

    if (dates) {
      startDate = dates[0]
      endDate = dates[1] ? dates[1] : currentDate
    }
    const params = new URLSearchParams()

    if (totalAmount?.min)
      params.append('greaterThanTotalAmount', totalAmount.min)
    if (totalAmount?.max) params.append('lessThanTotalAmount', totalAmount.max)
    if (dates) {
      if (startDate) params.append('startDate', formatDate(startDate))
      if (endDate) params.append('endDate', formatDate(endDate))
    }
    if (supplier) params.append('supplierName', 'Odan')
    if (orderNumber) params.append('orderNo', orderNumber)
    if (status) params.append('status', status)
    if (lazyState?.sortField) params.append('sortByField', lazyState.sortField)
    if (lazyState?.sortOrder) params.append('sortByOrder', lazyState.sortOrder)

    const requestParams = `?${params.toString()}`

    setcsvLoading(true)
    server
      .get(`/invoicesHeaders/exportOrders${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'x-system-name': 'Origins Direct',
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blobData)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'orders.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        setcsvLoading(false)
      })
      .catch((error) => {
        setcsvLoading(false)


      })
  }

  useEffect(() => {
    checkClearBtn()
    return getData()
  }, [lazyState])

  useEffect(() => initLazyState(), [searchParams])

  const onPage = (event) => {
    setLazyState({
      ...lazyState,
      page: event.page,
      first: event.first,
      rows: event.rows
    })
  }

  const onSort = (event) => {
    setLazyState(event)
  }

  const onFilter = (event) => {
    setLazyState(event)
  }

  // BODY TEMPLATES
  const statusBodyTemplate = (order) => {
    let statusColor
    let textColor
    switch (order.status) {
      case 'Cancelled':
        statusColor = '#EF4444'
        textColor = '#EF4444'
        break
      case 'Pending':
        statusColor = '#86888b'
        textColor = '#86888b'
        break
      case 'Received':
        statusColor = '#FFA500'
        textColor = '#FFA500'
        break
      case 'Confirmed':
        statusColor = '#22c55e'
        textColor = '#22c55e'
        break
    }

    return (
      <span
        className={`d-flex justify-content-center py-1 rounded-pill`}
        style={{ border: `1px solid ${statusColor}`, color: `${textColor}` }}
      >
        {order.status}
      </span>
    )
  }

  const orderTotalTemplate = (order) => formatCurrency(order.totalInvoiceAmount)
  const orderDateTemplate = (order) => formatDate(order.orderDate)
  const manufacturerTemplate = (rowData) =>
    rowData.invoicesDetails[0].manufacturer.name
  // Filter TEMPLATES
  const supplierFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      matchMode={options.filterModel.matchMode}
      placehloder='Manufacturer'
      icon='mdi mdi-cog'
    />
  )
  const statusFilterElement = (options) => (
    <DropDownTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e.value, options.index)}
      placeholder='Select Status'
      options={[
        { name: 'Confirmed', value: 'Confirmed' },
        { name: 'Pending', value: 'Pending' },
        { name: 'Received', value: 'Received' },
        { name: 'Cancelled', value: 'Cancelled' },
      ]}
    />
  )
  const orderTotalFilterElement = (options) => (
    <MinMaxTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
    />
  )

  const orderDateFilterElement = (options) => (
    <DateFilterTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e.value, options.index)}
    />
  )

  const orderNumberFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      placeholder='Order no.'
    />
  )

  const checkClearBtn = () => {
    if (lazyState?.sortField || lazyState?.sortOrder) {
      setClearBtnDisabled(false)
      return
    }
    for (const key in lazyState?.filters) {
      if (lazyState?.filters[key].value) {
        setClearBtnDisabled(false)
        return
      }
    }
    setClearBtnDisabled(true)
  }

  // TOOLBARS
  const leftToolbarTemplate = () => (
    <Button
      type='button'
      icon='pi pi-filter-slash'
      label='Clear'
      outlined
      onClick={() => {
        initLazyState()
        setSearchParams('')
      }}
      disabled={clearBtnDisabled}
    />
  )

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header='Manufacturer'
          field='supplier.name'
          rowSpan={2}
          sortable
          filter
          filterElement={supplierFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Order Number'
          rowSpan={2}
          sortable
          field='supplierInvoiceNo'
          filter
          filterElement={orderNumberFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Order Date'
          rowSpan={2}
          sortable
          field='orderDate'
          filter
          filterElement={orderDateFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Order Total'
          rowSpan={2}
          sortable
          field='totalInvoiceAmount'
          filter
          filterElement={orderTotalFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header='Status'
          rowSpan={2}
          sortable
          field='status'
          filter
          filterElement={statusFilterElement}
          showFilterOperator={false}
          showAddButton={false}
          showFilterMenuOptions={false}
        />
      </Row>
    </ColumnGroup>
  )

  return (
    <MainContentLayout
      title='Your Orders'
      dt={exportCSV}
      handleExport={true}
      loading={csvLoading}
    >
      <Toolbar className='mb-2' left={leftToolbarTemplate} />
      <DataTable
        ref={dt}
        lazy
        headerColumnGroup={headerGroup}
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        rowsPerPageOptions={[5, 10, 25, 50]}
        totalRecords={totalRecords}
        paginator
        value={orders}
        selectionMode='single'
        selection={selectedOrder}
        onSelectionChange={(e) => setSelectedOrder(e.value)}
        dataKey='id'
        onRowSelect={onRowSelect}
        tableStyle={{ minWidth: '50rem' }}
        loading={tableLoading}
        filters={lazyState?.filters}
        sortField={lazyState?.sortField}
        sortOrder={lazyState?.sortOrder}
        rows={lazyState?.rows}
        first={lazyState?.first}
        onPage={onPage}
        onSort={onSort}
        onFilter={onFilter}
        removableSort
        showGridlines
      >
        <Column
          field='invoicesDetails[0].manufacturer.name'
          body={manufacturerTemplate}
        />
        <Column field='supplierInvoiceNo' />
        <Column field='orderDate' body={orderDateTemplate} />
        <Column field='totalInvoiceAmount' body={orderTotalTemplate} />
        <Column field='status' body={statusBodyTemplate} />
      </DataTable>
    </MainContentLayout>
  )
}

export default OriginsDirectOrders
