import React, { useState, useEffect } from 'react'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { formatCurrency } from '../shared/utils'
import EditOrderItem from './Modals/EditOrderItem'
import { InputNumber } from 'primereact/inputnumber'

const SearchResults = ({
  results,
  pageLimit,
  totalCount,
  handleMyOrder,
  tableLoading,
  editItem,
  handleResultsUpdate,
  handleKeyPress
}) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [rowItemData, setRowItemData] = useState({})
  const [localResults, setLocalResults] = useState([])
  const [state, setState] = useState({
    first: 0,
    rows: 10,
    page: 0
  })

  const rowClassName = (rowData) => {
    return rowData.orderable === false ? 'gray-background' : ''
  }

  const packSizeBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.packSize}/{rowData.sellingPriceUnit}
      </div>
    )
  }
  
  const minMultBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.minimumOrderQuantity}/{rowData.multipleOrderQuantity}
      </div>
    )
  }
  
  const actionBodyTemplate = (rowData) => {
    return (
      <div className='d-flex justify-content-center'>
        {!rowData.quantity ? (
          <Button
            disabled={!rowData.orderable || !rowData.tempQuantity}
            type='button'
            label='Add to order'
            className='text-center'
            onClick={() => {
              if (!rowData.tempQuantity) return;
              
              const orderDetails = {
                ...rowData,
                quantity: rowData.tempQuantity,
                subtotal: rowData.tempQuantity * rowData.sellingPrice
              }
              handleMyOrder(orderDetails)
            }}
          />
        ) : (
          <Button
            icon='pi pi-pencil'
            rounded
            outlined
            className='mr-2'
            onClick={() => {
              setEditModalVisible(true)
              setRowItemData(rowData)
            }}
          />
        )}
      </div>
    )
  }

  useEffect(() => {
    if (results) {
      const updatedResults = results.map(item => ({
        ...item,
        tempQuantity: item.quantity || item.tempQuantity || 1
      }))
      setLocalResults(updatedResults)
    }
  }, [results])

  const handlePageChange = (e) => {
    setState(e)
    pageLimit(e)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleQuantityChange = (rowData, newValue) => {
    const updatedResults = localResults.map(item => {
      if (item.id === rowData.id) {
        return {
          ...item,
          tempQuantity: newValue
        }
      }
      return item
    })
    setLocalResults(updatedResults)
    handleResultsUpdate(updatedResults)
  }

  return (
    <>
      <DataTable
        value={localResults}
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        rowsPerPageOptions={[5, 10, 25, 50]}
        totalRecords={totalCount || 0}
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        rows={10}
        paginator
        lazy
        first={state.first}
        onPage={(e) => {
          handlePageChange({
            first: e.first,
            rows: e.rows,
            page: e.page,
            pageCount: Math.ceil((totalCount || 0) / e.rows)
          });
        }}
        showGridlines
        loading={tableLoading}
        dataKey='id'
        removableSort
        emptyMessage={() => {
          return (
            <div
              style={{
                height: '365px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontWeight: '400' }}>
                {tableLoading 
                  ? 'Loading...' 
                  : localResults?.length === 0 
                    ? 'No results found' 
                    : 'Use the search bar to find products'
                }
              </span>
            </div>
          )
        }}
      >
        <Column field='retailUpcCode' header='UPC#' />
        <Column field='itemDescription' header='Product Description' />
        <Column field='itemGroupMajorDescription' header='Manufacturer' />
        <Column field='drugIdentificationNo' header='DIN/NPN' />
        <Column header='Pack Size' body={packSizeBodyTemplate} />
        <Column header='Min/Mult' body={minMultBodyTemplate} />
        <Column header='Price' body={(e) => formatCurrency(e.sellingPrice)} />
        <Column
          header='Quantity'
          body={(rowData) => (
            <InputNumber
              value={rowData.tempQuantity}
              onValueChange={(e) => handleQuantityChange(rowData, e.value)}
              mode='decimal'
              showButtons
              min={0}
              disabled={!rowData.orderable || rowData.quantity}
              placeholder='Enter Quantity'
              useGrouping={false}
              minFractionDigits={0}
              maxFractionDigits={0}
              onKeyPress={(e) => handleKeyPress && handleKeyPress(e, rowData)}
            />
          )}
          style={{ minWidth: '150px' }}
        />
        <Column body={actionBodyTemplate} style={{ minWidth: '200px' }} />
      </DataTable>
      <EditOrderItem
        visible={isEditModalVisible}
        setVisible={setEditModalVisible}
        data={rowItemData}
        editItem={editItem}
      />
    </>
  )
}

export default SearchResults
