import { useContext, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Row, Spinner } from 'reactstrap'
import { AuthenticationContext } from './Auth/authentication.context'
import server from './server'

import { router } from './Routes'

import './App.css'
import './scss/theme.scss'

function App() {
  const {
    isLoading,
    setUser,
    setIsAdmin,
    setStatus,
    setBannerMsg,
    setError,
    setIsLoading,
  } = useContext(AuthenticationContext)

  const token = localStorage.getItem('accessToken')
  const sessionUser = JSON.parse(localStorage.getItem('user')) 
  const sessionIsAdmin = localStorage.getItem('isAdmin')
  const sessionUserStatus = localStorage.getItem('status')
  const sessionBannerMsg = localStorage.getItem('bannerMsg')

  useEffect(() => {
    setIsLoading(false)
    if (token) {
      if (sessionUser) {
        setUser(sessionUser)
        // setIsAdmin(sessionIsAdmin ? sessionIsAdmin : false);
        setStatus(sessionUserStatus ? sessionUserStatus : 0)
        setBannerMsg(sessionBannerMsg)
      } else {
        setIsLoading(true)
        getUser()
      }
    }
  }, [])

  const getUser = () => {
    server
      .get('/users', { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setIsAdmin(response.data.user.role === 'admin' ? true : false)
        setUser(response.data.user)
        setError(null)
        setIsLoading(false)
      })
      .catch((e) => {
        // setUser(null)
        // if (e.response.status === 401) {
        //   setUser(null)
       
        // }
        setIsLoading(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <Row
          className='justify-content-center align-items-center'
          style={{ height: '100vh' }}
        >
          <Spinner color='primary' />
        </Row>
      ) : (
        <RouterProvider className='App' router={router} />
      )}
    </>
  )
}

export default App
