import React, { useContext, useState, useEffect } from 'react'
import SidebarElement from './SidebarElement'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { AuthenticationContext } from '../../../Auth/authentication.context'
import { Carousel } from 'primereact/carousel'
import server from '../../../server'

const PharmacySidebarContent = () => {
  const navigate = useNavigate()
  const { status } = useContext(AuthenticationContext)
  const [loading, setLoading] = useState(false)
  const [advertisements, setAdvertisements] = useState([])

  // Consolidated styles
  const adCardStyles = {
    border: 'none',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    margin: '15px',
    height: '300px',
    width: '210px',
  }

  const adImageStyles = {
    width: '100%',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
  }

  const adTextStyles = {
    fontSize: '14px',
  }

  // Moved nodes array outside component
  const sidebarNodes = [
    {
      key: '1',
      label: 'Dashboard',
      link: '/',
      icon: 'mdi mdi-home',
    },
    {
      key: '2',
      label: 'Profile',
      link: '/pharmacy/profile',
      icon: 'mdi mdi-account',
    },
    {
      key: '3',
      label: 'Orders',
      link: '/pharmacy/orders',
      icon: 'mdi mdi-file-document-multiple',
    },
    {
      key: '4',
      label: 'Manufacturers',
      link: '/pharmacy/manufacturers',
      icon: 'mdi mdi-factory',
    },
    {
      key: '5',
      label: 'Manufacturers Reports',
      link: '/pharmacy/manufacturers-reports',
      icon: 'mdi mdi-file-chart-outline',
    },
    {
      key: '6',
      label: 'Logout',
      link: 'logout',
      icon: 'mdi mdi-logout',
    },
  ]

  const { collapsed, module } = useContext(AuthenticationContext)
  const [userModule, setUSerModule] = useState('')

  useEffect(() => {
    if (module) {
      setUSerModule(module)
    }
  }, [module])
  const statusButtonHide = (label) => {
    switch (label) {
      case 'Orders':
        return parseInt(status) < 3 ? true : false
      case 'Manufacturers':
        return parseInt(status) < 1 ? true : false
      default:
        return false
    }
  }

  const getAdvertisements = async () => {
    setLoading(true)
    try {
      const response = await server.get('/ads', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      const ads = response?.data?.ads || []
      setAdvertisements(ads)
    } catch (error) {
      console.error('Error fetching advertisements:', error)
    } finally {
      setLoading(false)
    }
  }

  const adsTemplate = (advertisement) => (
    <div>
      <div style={adCardStyles}>
        <div style={{ overflow: 'hidden', padding: '10px' }}>
          {advertisement.image && (
            <img
              src={advertisement.image}
              alt={advertisement.title}
              style={adImageStyles}
            />
          )}
        </div>
        <div style={{ padding: '10px' }}>
          <p className='fw-bold mb-1' style={adTextStyles}>
            {advertisement.title}
          </p>
          <p className='text-muted mb-3' style={adTextStyles}>
            {advertisement.message}
          </p>
          {advertisement?.link && (
            <a
              href={`https://${advertisement.link}`}
              className='text-decoration-none text-primary'
              style={{ ...adTextStyles, padding: '0px' }}
            >
              Learn More →
            </a>
          )}
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    getAdvertisements()
  }, [])
  return (
    <>
      <li>
        {collapsed ? (
          userModule === 'Origins Direct' && (
            <a className={`${status < 3 ? 'status-hidden' : ''} has-arrow`}>
              <i
                className='mdi mdi-pen'
                onClick={() => navigate('/pharmacy/add-orders')}
              ></i>
              <span
                className=''
                onClick={() => navigate('/pharmacy/add-orders')}
              >
                New Order
              </span>
            </a>
          )
        ) : (
          <div
            className={`${
              status < 3 ? 'status-hidden' : ''
            } d-flex justify-content-center w-100 p-2`}
          >
            {userModule === 'Origins Direct' && (
              <Button
                type='button'
                label='Create New Order'
                onClick={() => navigate('/pharmacy/add-orders')}
              />
            )}
          </div>
        )}
      </li>
      {sidebarNodes.map((menuItem) =>
        !statusButtonHide(menuItem.label) ? (
          <SidebarElement
            key={menuItem.key}
            name={menuItem.label}
            link={menuItem.link}
            iconClass={menuItem.icon}
            notifications={menuItem.notifications}
            children={menuItem.children}
          />
        ) : null
      )}

      <li>
        {!collapsed && advertisements.length > 0 && (
          <div className='card flex justify-content-center bg-transparent shadow-none'>
            <Carousel
              value={advertisements}
              numVisible={1}
              numScroll={1}
              verticalViewPortHeight='2400px'
              itemTemplate={adsTemplate}
              showIndicators={false}
            />
          </div>
        )}
      </li>
    </>
  )
}

export default PharmacySidebarContent
