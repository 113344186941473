import React, { useContext, useState, useEffect } from 'react'
import MainContentLayout from '../../Layout/MainContentLayout'
import { InputText } from 'primereact/inputtext'
import { Row, Col, Tooltip, Spinner } from 'reactstrap'
import { Button } from 'primereact/button'
import Modals from '../../shared/Modals'
import StatusBadge from '../../shared/StatusBadge'
import StatusDropdown from '../../shared/StatusDropdown'
import server from '../../../server'
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { AuthenticationContext } from '../../../Auth/authentication.context'
import DeleteModal from '../../shared/DeleteModal'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
export default function ManufacturersPharmacy() {
  const { status, setStatus, setBannerMsg } = useContext(AuthenticationContext)
  const [accountNo, setAccountNo] = useState(null)
  const [manufacturerData, setManufacturerData] = useState([])
  const [selectedManufacturer, setSelectedManufacturer] = useState(null)
  const [deleteSelectedManufacturer, setDeleteSelectedManufacturer] =
    useState(null)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [activeManufacturer, setActiveManufacturer] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [filterStatus, setFilterStatus] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState({})
  const [cantFind, setCantFind] = useState(false)
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }


  const handleFilterChange = (status) => {
    setFilterStatus(status)
  }

  const filteredManufacturers = manufacturerData
    .filter((manufacturer) =>
      manufacturer.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((manufacturer) =>
      filterStatus ? manufacturer.status === filterStatus : true
    )

  const getManufacturerData = () => {
    setLoading(true)
    server
      .get('/manufacturers/getManafucaturerWithStatus', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturerData = response?.data?.manufacturers || []
        setManufacturerData(manufacturerData)
        setStatus(response?.data?.userBanner?.status)
        setBannerMsg(response?.data?.userBanner?.message)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching Manufacturer Data:', error)
        setLoading(false)
      })
  }

  const handleEditManufacturer = (values, id) => {
 

    // Update the email for the first rep
    const updatedData = {
      ...values,
      reps: values.reps.map((rep) => ({
        ...rep,
        email: `${rep.email}@${selectedManufacturer?.emailDomain}`,
      })),
    }

    

    server
      .patch(`/manufacturerPharmacyCode/pharmacy/${id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        getManufacturerData() // refresh the manufacturer list
        setIsEditModalVisible(false) // close modal
      })
      .catch((error) => {
        console.error('Error updating manufacturer:', error)
      })
  }

  useEffect(() => {
    getManufacturerData()
  }, [])
  useEffect(() => {
    setAccountNo(null)
  }, [isModalVisible])

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }
  const toggleDropdown = (id) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }
  const handleEditClick = (manufacturer) => {
    setSelectedManufacturer(manufacturer)
    setIsEditModalVisible(true)
  }
  const handleDeleteClick = (manufacturer) => {
    setSelectedManufacturer(manufacturer)
    setDeleteSelectedManufacturer(true) // Show the delete modal
  }

  const hideDeleteAdminDialog = () => {
    setDeleteSelectedManufacturer(false) // Hide the modal
    setSelectedManufacturer(null) // Clear the selected manufacturer
  }

  const deleteAdminDialogFooter = (
    <>
      <Button label='No' icon='pi pi-times' onClick={hideDeleteAdminDialog} />
      <Button
        label='Yes'
        icon='pi pi-check'
        onClick={() => handleConfirmDelete(selectedManufacturer)}
      />
    </>
  )

  const handleConfirmDelete = (manufacturer) => {
    // Add your delete logic here

    server
      .delete(
        `/manufacturerPharmacyCode/pharmacy/${selectedManufacturer?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      )
      .then((response) => {
        setLoading(false)
        setDeleteSelectedManufacturer(false)
        getManufacturerData()
      })
      .catch((error) => {
        setLoading(false)
     
      })
    hideDeleteAdminDialog() // Close the modal after deletion
  }
  const handleOutsideClick = (e) => {
    if (!e.target.closest('.dropdown-container')) {
      setDropdownOpen({})
    }
  }

  // Close dropdown on outside click
  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])
  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string(),
    // accountNumber: Yup.string().required('Account number is required'),
    reps: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
          .matches(
            /^[^@]+$/, // This regex ensures that only the part before the '@' is allowed.
            'Only the email portion before the @ should be added.'
          )
          .required('Email is required'),
      })
    ),
  })

  const reformatArray = (arr, param) => {
    return arr?.map((item) => ({
      ...item,
      [param]: item[param].split('@')[0], // Modify the specified property
    }))
  }



  const handleActivate = (values) => {
    const formData = {
      manufacturerId: activeManufacturer?.id,
      manufacturerCode: values.accountNumber ? values.accountNumber : null,
      reps: values.reps,
    }
    const updatedData = {
      ...formData,
      reps: formData.reps?.map((rep) => ({
        ...rep,
        email: `${rep.email}@${activeManufacturer.emailDomain}`,
      })),
    }

    server
      .post('manufacturerPharmacyCode/pharmacy', updatedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setStatus(response?.data?.userBanner?.status)
        setBannerMsg(response?.data?.userBanner?.message)
        setIsModalVisible(false)
        getManufacturerData()
      })
      .catch((error) => {
        console.error('Error activating manufacturer:', error)
      })
  }

  return (
    <MainContentLayout title='Manufacturers'>
      <div className='d-flex justify-content-end align-items-center mb-4 gap-4'>
        <StatusDropdown onChange={handleFilterChange} />
        <div className='p-inputgroup w-25' style={{ height: '40px' }}>
          <InputText
            placeholder='Search'
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button
            className='bg-primary'
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
          >
            <span className='mdi mdi-magnify text-white'></span>
          </Button>
        </div>
      </div>

      {loading ? (
        <div className='d-flex justify-content-center mt-5'>
          <Spinner color='primary' />
        </div>
      ) : (
        <div>
          <Row className='g-3'>
            {filteredManufacturers.length > 0 ? (
              filteredManufacturers?.map((manufacturer, index) => (
                <Col key={index} lg='4' md='6' sm='12' className='d-flex'>
                  <div className='card-background-color mb-4 h-100 w-100 d-flex'>
                    <div className='py-2 px-3 d-flex flex-column justify-content-center gap-3 w-100'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <StatusBadge status={manufacturer.status} />
                        <div className='d-flex  align-items-center'>
                          <i
                            className='mdi mdi-information-outline'
                            id={`infoTooltip-${index}`}
                            style={{
                              fontSize: '1.5rem',
                              cursor: 'pointer',
                            }}
                          ></i>
                          <Tooltip
                            style={{ backgroundColor: '#AFB0B0' }}
                            placement='top'
                            isOpen={tooltipOpen[`infoTooltip-${index}`]}
                            target={`infoTooltip-${index}`}
                            toggle={() => toggleTooltip(`infoTooltip-${index}`)}
                          >
                            For further assistance, call the generic
                            manufacturer or contact your rep
                          </Tooltip>
                          {(manufacturer.status === 'active' ||
                            manufacturer.status === 'pending') && (
                            <div
                              className='dropdown-container'
                              style={styles.container}
                            >
                              {/* Dropdown Button */}
                              <button
                                className='btn'
                                onClick={() => toggleDropdown(index)}
                                style={styles.button}
                              >
                                <i
                                  className='fas fa-ellipsis-v'
                                  id={`infoTooltip-${index}`}
                                  style={{
                                    fontSize: '1rem',
                                    cursor: 'pointer',
                                  }}
                                ></i>
                                {/* Ellipsis icon */}
                              </button>

                              {/* Dropdown Menu */}
                              {dropdownOpen[index] && (
                                <ul style={styles.dropdownMenu}>
                                  {manufacturer.status === 'active' && (
                                    <li style={styles.dropdownItem}>
                                      <button
                                        className='btn bg-transparent border-0 w-100 d-flex align-items-center'
                                        onClick={() =>
                                          handleEditClick(manufacturer)
                                        }
                                      >
                                        <i className='fas fa-pen text-primary me-3 '></i>
                                        Edit
                                      </button>
                                    </li>
                                  )}

                                  <li style={styles.dropdownItem}>
                                    <button
                                      className='btn bg-transparent border-0 w-100 d-flex align-items-center'
                                      onClick={() =>
                                        handleDeleteClick(manufacturer)
                                      }
                                    >
                                      <i className='fas fa-trash text-danger me-3'></i>
                                      Delete
                                    </button>
                                  </li>
                                </ul>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='d-flex p-0 justify-content-between'>
                        <span className='fw-bold fs-5'>
                          {manufacturer?.name}
                        </span>
                        {manufacturer?.new === true && (
                          <span
                            style={{ color: '#2FB500' }}
                            className='self-stretch my-auto text-sm text-center text-lime-600'
                          >
                            New
                          </span>
                        )}
                      </div>

                      {manufacturer.status === 'available' ? (
                        <div className='d-flex justify-content-between gap-2'>
                          <button
                            className='btn btn-primary w-100'
                            onClick={() => {
                              setActiveManufacturer(manufacturer)
                              setIsModalVisible(true)
                            }}
                          >
                            Connect Now
                          </button>
                        </div>
                      ) : (
                        <div className='d-flex justify-content-between'>
                          {manufacturer?.AccountNo ? (
                            <>
                              <span className=' fs-6'>Account no: </span>
                              <span>{manufacturer?.AccountNo}</span>
                            </>
                          ) : (
                            <span className='fs-6'>
                              We will send a notification once available
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <Col>
                <div className='text-center p-4'>
                  <span className='fs-5 fw-light text-muted'>
                    No Manufacturers match this filter or search.
                  </span>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}

      {/* Modal for Activating Manufacturer */}
      <Modals
        ModalHeader='Activate Manufacturer'
        btnName='Activate'
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        onButtonClick={() => {
          document.getElementById('formik-submit-btn').click()
        }}
      >
        <div className='modal-body'>
          <Formik
            initialValues={{
              accountNumber: '',
              reps: [{ name: '', email: '' }],
            }}
            validationSchema={validationSchema}
            onSubmit={handleActivate}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <div className='mt-2'>
                  <label htmlFor='accountNumber'>
                    Do you have an account with {activeManufacturer?.name}?
                  </label>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-center',
                      gap: '20px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-center',
                        gap: '5px',
                      }}
                    >
                      <RadioButton
                        inputId='option1'
                        name='Yes'
                        value={true}
                        onChange={(e) => setAccountNo(e.value)}
                        checked={accountNo === true}
                      />
                      <label htmlFor='option1' className='ml-2'>
                        Yes
                      </label>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-center',
                        gap: '5px',
                      }}
                    >
                      <RadioButton
                        inputId='option2'
                        name='No'
                        value={false}
                        onChange={(e) => setAccountNo(e.value)}
                        checked={accountNo === false}
                      />
                      <label htmlFor='option2' className='ml-2'>
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {accountNo && (
                  <>
                    {!cantFind && (
                      <div className='form-group mb-1'>
                        <label htmlFor='accountNumber'>Account no.</label>
                        <Field
                          type='text'
                          className='form-control'
                          id='accountNumber'
                          name='accountNumber'
                          placeholder='Enter Account no.'
                        />
                        <ErrorMessage
                          name='accountNumber'
                          component='div'
                          className='text-danger'
                        />
                      </div>
                    )}

                    <div className='d-flex align-items-center mt-2'>
                      <Checkbox
                        inputId='cantFind'
                        name='cantFind'
                        checked={cantFind}
                        value={cantFind}
                        onChange={() => setCantFind(!cantFind)}
                      />
                      <label htmlFor='active' className='mx-2 my-0'>
                        <p className='mb-0'>I can't find it now.</p>
                      </label>
                    </div>
                  </>
                )}
                {accountNo === false && (
                  <span className='fw-light lead fs-6  mb-5'>
                    {activeManufacturer?.creditApplication ? (
                      <a
                        className='text-black'
                        href={activeManufacturer?.creditApplication}
                        download
                      >
                        {/* Don't have an account? Download the credit application
                               here. */}
                        No problem, the rep will contact you. In the meantime,
                        download the credit application{' '}
                        <span className='text-decoration-underline'>here</span>
                      </a>
                    ) : (
                      'No problem, the rep will contact you. In the meantime.'
                      // 'No credit application available.'
                    )}
                  </span>
                )}

                <FieldArray name='reps'>
                  {({ push, remove }) => (
                    <>
                      <div className='form-group col-auto d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-dark border-0 mt-2 d-flex gap-2 align-items-center'
                          id='addFieldButton'
                          onClick={() => push({ name: '', email: '' })}
                        >
                          <span className='fw-light lead fs-6'>Add Rep</span>
                          <span className='mdi mdi-plus'></span>
                        </button>
                      </div>
                      {values?.reps?.map((rep, index) => (
                        <div
                          className='form-row d-flex align-items-start gap-2 mb-3'
                          key={index}
                        >
                          <div className='form-group w-100'>
                            <label htmlFor={`reps.${index}.name`}>
                              Name of Rep {index + 1}
                            </label>
                            <Field
                              type='text'
                              className='form-control'
                              id={`reps.${index}.name`}
                              name={`reps.${index}.name`}
                              placeholder='Enter Name'
                              value={values?.reps[index]?.name}
                            />
                            {/* Add a container for validation messages with a fixed height */}
                            <div style={{ minHeight: '20px' }}>
                              <ErrorMessage
                                name={`reps.${index}.name`}
                                component='small'
                                className='text-danger'
                              />
                            </div>
                          </div>

                          <div className='form-group w-100'>
                            <label htmlFor={`reps.${index}.email`}>
                              Email of Rep {index + 1}
                            </label>
                            <div className='input-group'>
                              {/* Input for Email Prefix */}
                              <Field
                                type='text'
                                className='form-control'
                                id={`reps.${index}.email`}
                                name={`reps.${index}.email`}
                                placeholder='Enter Email'
                                value={values.reps[index].email}
                              />
                              {/* Static domain part */}
                              <span className='input-group-text'>
                                @{activeManufacturer?.emailDomain}
                              </span>
                            </div>
                            {/* Validation messages */}
                            <div style={{ minHeight: '20px' }}>
                              <ErrorMessage
                                name={`reps.${index}.email`}
                                component='small'
                                className='text-danger'
                              />
                            </div>
                          </div>

                          {values.reps.length > 1 && (
                            <div
                              className='form-group align-self-center'
                              style={{ marginTop: '10px' }}
                            >
                              <button
                                style={{ fontSize: '20px' }}
                                type='button'
                                className='text-danger bg-transparent border-0'
                                onClick={() => remove(index)}
                              >
                                <span className='mdi mdi-trash-can'></span>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <div className='form-group mt-3 d-flex justify-content-end'>
                  <Button
                    id='formik-submit-btn'
                    type='submit'
                    color='primary'
                    style={{ display: 'none' }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modals>

      {/* Modal for editing Manufacturer */}
      <Modals
        ModalHeader='Edit Manufacturer'
        btnName='Edit'
        visible={isEditModalVisible}
        setVisible={setIsEditModalVisible}
        onButtonClick={() => {
          document.getElementById('formik-submit-btn').click()
        }}
      >
        <div className='modal-body'>
          <Formik
            initialValues={{
              manufacturerCode: selectedManufacturer?.AccountNo || '',
              reps: reformatArray(selectedManufacturer?.reps, 'email') || [
                { name: '', email: '' },
              ],
            }}
            validationSchema={Yup.object().shape({
              manufacturerCode: Yup.string().required(
                'Account number is required'
              ),
              reps: Yup.array().of(
                Yup.object().shape({
                  name: Yup.string().required('Name is required'),
                  email: Yup.string()
                    .matches(
                      /^[^@]+$/, // This regex ensures that only the part before the '@' is allowed.
                      'Email must contain characters before the "@" symbol, and nothing after it.'
                    )
                    .required('Email is required'),
                })
              ),
            })}
            // onSubmit={handleEditManufacturer}
            onSubmit={(values) => {
        
              handleEditManufacturer(values, selectedManufacturer.id)
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className='form-group mb-1'>
                  <label htmlFor='manufacturerCode'>Account no.</label>
                  <Field
                    type='text'
                    className='form-control'
                    id='manufacturerCode'
                    name='manufacturerCode'
                    placeholder='Enter Account no.'
                  />
                  <ErrorMessage
                    name='manufacturerCode'
                    component='div'
                    className='text-danger'
                  />
                </div>
                <span className='fw-light lead fs-6 text-decoration-underline mb-3'>
                  {activeManufacturer?.creditApplication ? (
                    <a
                      className='text-black'
                      href={activeManufacturer?.creditApplication}
                      download
                    >
                      Don't have an account? Download the credit application
                      here.
                    </a>
                  ) : (
                    'No credit application available.'
                  )}
                </span>

                <FieldArray name='reps'>
                  {({ push, remove }) => (
                    <>
                      <div className='form-group col-auto d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-dark border-0 mt-2 d-flex gap-2 align-items-center'
                          id='addFieldButton'
                          onClick={() => push({ name: '', email: '' })}
                        >
                          <span className='fw-light lead fs-6'>Add Rep</span>
                          <span className='mdi mdi-plus'></span>
                        </button>
                      </div>
                      {values?.reps?.map((rep, index) => (
                        <div
                          className='form-row d-flex align-items-start gap-2 mb-3'
                          key={index}
                        >
                          <div className='form-group w-100'>
                            <label htmlFor={`reps.${index}.name`}>
                              Name of Rep {index + 1}
                            </label>
                            <Field
                              type='text'
                              className='form-control'
                              id={`reps.${index}.name`}
                              name={`reps.${index}.name`}
                              placeholder='Enter Name'
                              value={values?.reps[index]?.name}
                            />
                            {/* Add a container for validation messages with a fixed height */}
                            <div style={{ minHeight: '20px' }}>
                              <ErrorMessage
                                name={`reps.${index}.name`}
                                component='small'
                                className='text-danger'
                              />
                            </div>
                          </div>

                          <div className='form-group w-100'>
                            <label htmlFor={`reps.${index}.email`}>
                              Email of Rep {index + 1}
                            </label>
                            <div className='input-group'>
                              {/* Input for Email Prefix */}
                              <Field
                                type='text'
                                className='form-control'
                                id={`reps.${index}.email`}
                                name={`reps.${index}.email`}
                                placeholder='Enter Email'
                                value={values.reps[index].email}
                              />
                              {/* Static domain part */}
                              <span className='input-group-text'>
                                @{selectedManufacturer?.emailDomain}
                              </span>
                            </div>
                            {/* Validation messages */}
                            <div style={{ minHeight: '20px' }}>
                              <ErrorMessage
                                name={`reps.${index}.email`}
                                component='small'
                                className='text-danger'
                              />
                            </div>
                          </div>

                          {values?.reps?.length > 1 && (
                            <div
                              className='form-group align-self-center'
                              style={{ marginTop: '10px' }}
                            >
                              <button
                                style={{ fontSize: '20px' }}
                                type='button'
                                className='text-danger bg-transparent border-0'
                                onClick={() => remove(index)}
                              >
                                <span className='mdi mdi-trash-can'></span>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <div className='form-group mt-3 d-flex justify-content-end'>
                  <Button
                    id='formik-submit-btn'
                    type='submit'
                    color='primary'
                    style={{ display: 'none' }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modals>

      <DeleteModal
        state={deleteSelectedManufacturer}
        footer={deleteAdminDialogFooter}
        onHide={hideDeleteAdminDialog}
        deletedItem={selectedManufacturer}
        name={selectedManufacturer ? selectedManufacturer.name : ''}
      />
    </MainContentLayout>
  )
}
const styles = {
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  button: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  dropdownMenu: {
    position: 'absolute',
    right: 0,
    top: '100%',
    background: 'white',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    width: '150px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    zIndex: 999,
  },
  dropdownItem: {
    padding: '5px',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: '#333',
  },
}
