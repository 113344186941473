import React, { useState, useEffect, useRef } from 'react'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { formatCurrency } from '../shared/utils'
import DeleteModal from '../shared/DeleteModal'
import EditOrderItem from './Modals/EditOrderItem'
import OrderSummary from './Modals/OrderSummary'
import SuccessModal from './Modals/SuccessModal'
const MyOrders = ({ myOrder, handleDeleteItem, clear, editItem }) => {
  const [deleteOrderItem, setDeleteOrderItem] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const [orderSummaryVisible, setOrderSummaryVisible] = useState(false)
  const [myOrderData, setMyOrderData] = useState([])
  const [rowItemData, setRowItemData] = useState({})
  const [tableLoading, setTableLoading] = useState(false)
  const [succcess, setSuccess] = useState(false)
  const [state, setState] = useState({
    first: 0,
    rows: 5,
    page: 0,
  })
  const [products, setProducts] = useState([])

  useEffect(() => {
    if (myOrder) {
      setMyOrderData(myOrder)
    }
  }, [myOrder])

  useEffect(() => {
    if (succcess === false) {
      clear(products)
    }
  }, [succcess])

  // const handleClear = (products) => {
  //   clear(products)
  // }
  const calculateTotal = () => {
    let total = 0

    myOrderData.forEach((order) => {
      if (Array.isArray(order.subtotal)) {
        total += order.subtotal.reduce((acc, curr) => acc + curr, 0)
      } else {
        total += order.subtotal || 0
      }
    })

    return total
  }

  const handleProducts = (products) => {
    setProducts(products)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex justify-content-center gap-2'>
          <Button
            icon='pi pi-pencil'
            rounded
            outlined
            className='mr-2'
            onClick={() => {
              setModalVisible(true)
              setRowItemData(rowData)
            }}
          />

          <Button
            icon='pi pi-trash'
            rounded
            outlined
            severity='danger'
            onClick={() => {
              setDeleteOrderItem(true)
              setRowItemData(rowData)
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  // Closes Delete Dialog Modal
  const hideDeleteOrderItem = () => {
    setDeleteOrderItem(false)
  }

  const handleDeleteItems = () => {
    handleDeleteItem(rowItemData)
    setDeleteOrderItem(false)
  }

  const deleteOrderItemFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        outlined
        onClick={hideDeleteOrderItem}
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        severity='danger'
        onClick={handleDeleteItems}
      />
    </React.Fragment>
  )

  const dt = useRef(null)
  const scrollToOrder = useRef(null)

  const exportCSV = () => {
    dt.current.exportCSV()
  }
  const rightToolbarTemplate = () => (
    <div className="d-flex gap-2">
      <Button
        label='Export CSV'
        icon='pi pi-upload'
        outlined
        className='p-button-help'
        onClick={exportCSV}
      />
      <Button
        type='button'
        label='Place Order'
        disabled={calculateTotal() > 0 ? false : true}
        onClick={() => {
          setOrderSummaryVisible(true)
        }}
      />
    </div>
  )
  return (
    <>
    <a href='#componentToScrollTo' ref={scrollToOrder} style={{display:"hidden"}}></a>
    <div  id="componentToScrollTo">
      <DataTable
        ref={dt}
  
        value={myOrderData ? myOrderData : []}
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginator
        showGridlines
        loading={tableLoading}
        dataKey='id'
        removableSort
        // globalFilterFields={['name']}
        emptyMessage={() => {
          return (
            <div
              style={{
                height: '365px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontWeight: '400' }}>
                The products you add to your order will appear here
              </span>
            </div>
          )
        }}
        sortField={state.sortField}
        sortOrder={state.sortOrder}
        filters={state.filters}
        rows={state.rows}
        first={state.first}
        onPage={(e) => setState(e)}
        onSort={(e) => {
          e.first = 0
          setState(e)
        }}
        onFilter={(e) => setState(e)}
      >
        {/* <Column
          field='itemCode'
          header='Item    '
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={manufacturerFilterElement}
          style={{ fontWeight: 'bold' }}
        /> */}
        <Column
          field='retailUpcCode'
          header='UPC#'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={orderAmountFilterElement}
          //   body={orderAmountBodyTemplate}
        />
        <Column
          field='itemDescription'
          header='Product Description'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
        />
        <Column
          field='itemGroupMajorDescription'
          header='Manufacturer'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
        />
        <Column
          field='drugIdentificationNo'
          header='DIN/NPN'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
        />
        <Column
          // field='Price'
          header='Price'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
          body={(e) => formatCurrency(e.sellingPrice)}
        />
        <Column
          field='quantity'
          header='Quantity'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          //   body={paAmountBodyTemplate}
        />
        <Column
          field='subtotal'
          header='Subtotal'
          //   sortable
          //   filter
          //   showFilterMenuOptions={false}
          //   filterElement={paAmountFilterElement}
          body={(e) => formatCurrency(e.subtotal)}
        />
        <Column body={actionBodyTemplate} style={{ minWidth: '200px' }} />
      </DataTable>
      </div>
      {myOrderData.length !== 0 && (
        <Toolbar className='mt-2' right={rightToolbarTemplate} />
      )}
      <div>
        <div
          className='d-flex justify-content-center p-4'
          style={{
            position: 'fixed',
            bottom: '5px',
            left: '5x',
            transform: 'none',
            background: 'transparent',
            width: 'fit-content',
            zIndex: '100000',
          }}
        >
          <div
            style={{
              border: '2px solid #4F46E5',
              padding: '1px',
              borderRadius: '10px',
              background: 'white',
              width: 'fit-content'
            }}
            className='d-flex align-items-center gap-4 px-5 py-3'
          >
            <div className='d-flex flex-column align-items-center px-2'>
              <p className='fw-bold m-0'>TOTAL</p>
              <p className='fw-bold m-0'>{formatCurrency(calculateTotal())}</p>
            </div>
            <Button
              type="button"
              label="View Order"
              className="text-center"
              onClick={() => {
                scrollToOrder.current.focus()
              }}
            />
          </div>
        </div>
      </div>

      <DeleteModal
        state={deleteOrderItem}
        footer={deleteOrderItemFooter}
        onHide={hideDeleteOrderItem}
        deletedItem={true}
        name={'item'}
      />
      <EditOrderItem
        visible={isModalVisible}
        setVisible={setModalVisible}
        data={rowItemData}
        editItem={editItem}
      />
      <OrderSummary
        visible={orderSummaryVisible}
        setVisible={setOrderSummaryVisible}
        orderData={myOrderData}
        total={formatCurrency(calculateTotal())}
        subtotal={formatCurrency(calculateTotal())}
        handleSuccess={() => setSuccess(true)}
        ordableProducts={handleProducts}
      />
      <SuccessModal
        visible={succcess}
        setVisible={setSuccess}
        // clear={() => clear()}
      />
     
    </>
  )
}

export default MyOrders
