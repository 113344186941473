import React from 'react'
import Logo from '../../assets/images/ORIGINS-DIRECT.png'
import originsSystemsLogo from '../../assets/images/oscolored.png'

const AuthLayout = ({ children, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <div className='loading-container dark:bg-dark'>
          <span className='spinner'></span>
        </div>
      ) : (
        <div className='auth-layout p-1'>
          {/* Left Side (Fixed) */}
          <div className='left-side'>
            <div className='overlay'></div>

            {/* Center the logo */}
            <div className='logo-container'>
              <img
                src={Logo}
                alt='Logo'
                className='logo'
                style={{ width: '9rem' }}
              />
            </div>

            {/* Subsidiary info positioned at the bottom */}
            <div className='subsidiary-container'>
              <p>Subsidiary of</p>
              <img
                src={originsSystemsLogo}
                alt='originsSystemsLogo'
                className='logo-two'
              />
            </div>
          </div>

          <div className='right-side'>
            <div className='content'>{children}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default AuthLayout
