import React, { useState, useContext, useEffect, useRef } from 'react'
// import styles from "../../scss/"
import { Toast } from 'primereact/toast'
import Modals from '../shared/Modals'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Spinner,
} from 'reactstrap'
import { AuthenticationContext } from '../../Auth/authentication.context'
import { ToastPopUp } from '../shared/utils'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import PlacesAutocomplete from '../shared/PlacesAutoComplete'
import { Checkbox } from 'primereact/checkbox'
import EditUserProfileInfo from './editUserProfileInfo'
const UserProfile = (props) => {
  const {
    user,
    isAdmin,
    toastStatus,
    onProfileUpdate,
    onChangePasswordProfile,
    onGetUserData,
    loading,
    success,
    status,
  } = useContext(AuthenticationContext)

  const toast = useRef(null)
  const [isOwnerContactDifferent, setIsOwnerContactDifferent] = useState(true)
  const [ownerPhone, setOwnerPhone] = useState(
    user?.pharmacyOwnerTelephone || ''
  )
  const [ownerEmail, setOwnerEmail] = useState(user?.pharmacyOwnerEmail || '')
  const [editMode, setEditMode] = useState(false)
  const [editDisplayName, setEditDisplayName] = useState('')
  const [editName, setEditName] = useState(user?.legalName)
  const [editTradeName, setEditTradeName] = useState(user?.tradeName)
  const [editAccreditationNo, setAccreditationNo] = useState('')
  const [editTelephone, setEditTelephone] = useState(user?.telephone)
  const [editFax, setEditFax] = useState(user?.fax)
  const [editPharmacyOwnerName, setEditPharmacyOwnerName] = useState(
    user?.pharmacyOwnerName
  )
  const [editPharmacyOwnerTelephone, setEditPharmacyOwnerTelephone] =
    useState('')
  const [editPharmacyOwnerEmail, setEditPharmacyOwnerEmail] = useState('')
  const [changePasswordMode, setChangePasswordMode] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(
    user?.shippingAddress
  )
  const [userLoading, setUserLoading] = useState(false)

  const [originsDirectModule, setOriginsDirectModule] = useState('')
  const [originsManagementModule, setOriginsManagementModule] = useState('')
  const [isPharmacistInfoDifferent, setIsPharmacistInfoDifferent] =
    useState(false)

  // Initialize state for Pharmacy Owner's phone and email
  const [pharmacyOwnerTelephone, setPharmacyOwnerTelephone] = useState(
    user.pharmacyOwnerTelephone || ''
  )
  const [pharmacyOwnerEmail, setPharmacyOwnerEmail] = useState(
    user.pharmacyOwnerEmail || ''
  )
  const handleOwnerContactChange = (e) => {
    setIsOwnerContactDifferent(e.checked)
    if (!e.checked) {
      // If checkbox is unchecked, clear the owner phone and email
      setOwnerPhone('')
      setOwnerEmail('')
    }
  }

  // Check if pharmacy owner's phone or email exists, and auto-check the box
  useEffect(() => {
    if (pharmacyOwnerTelephone || pharmacyOwnerEmail) {
      setIsPharmacistInfoDifferent(true) // Automatically check the box
    }
  }, [pharmacyOwnerTelephone, pharmacyOwnerEmail])

  useEffect(() => {
    const originsDirect = user?.pharmacyModules?.find(
      (module) => module.name === 'Origins Direct'
    )
    if (originsDirect) {
      setOriginsDirectModule(originsDirect.name)
    }

    const originsManagement = user?.pharmacyModules?.find(
      (module) => module.name === 'Origins Management'
    )
    if (originsManagement) {
      setOriginsManagementModule(originsManagement.name)
    }
  }, [user])
  useEffect(() => {
    if (toastStatus?.toastStatus) {
      ToastPopUp(toast, toastStatus?.toastStatus, toastStatus?.msg)
    }
  }, [toastStatus])

  const handlePasswordSubmit = () => {
    if (
      currentPassword !== '' &&
      newPassword !== '' &&
      confirmPassword !== ''
    ) {
      onChangePasswordProfile(
        currentPassword,
        newPassword,
        confirmPassword
      ).then(() => {
        // onGetUserData()
        setEditMode(false)
        setUserLoading(true)
      })
    }
  }

  const handleEditSubmit = () => {
    // Check if the phone and email should be sent as null
    const updatedPharmacyOwnerTelephone = isOwnerContactDifferent
      ? ownerPhone
      : null

    const updatedPharmacyOwnerEmail = isOwnerContactDifferent
      ? ownerEmail
      : null

    // Call the onProfileUpdate function with the prepared data
    onProfileUpdate(
      editDisplayName,
      editName,
      editTradeName,
      editAccreditationNo,
      selectedAddress,
      editTelephone,
      editFax,
      selectedShippingAddress,
      editPharmacyOwnerName,
      updatedPharmacyOwnerTelephone,
      updatedPharmacyOwnerEmail
    )

    // Update the UI state
    setEditMode(false)
    setUserLoading(true)
  }
  useEffect(() => {
    if (success === true) {
      onGetUserData()
    }
  }, [success])
  useEffect(() => {
    if (loading === false) {
      setUserLoading(false)
    }
  }, [loading])
  const pharmacyModules = user?.pharmacyModules?.map((module, index) => (
    <span
      className='badge bg-primary rounded-pill text-light py-2 px-3 fw-bold mx-1'
      key={index}
    >
      {module.name}
    </span>
  ))

  const manufacturers = () => {
    let manufacturersNames = user?.manufacturers?.map((itm, index) => {
      return (
        <h4 className='m-0' key={index}>
          <span className='badge bg-primary  rounded-pill text-light py-2 px-3 fw-bold '>
            {itm?.name}
          </span>
        </h4>
      )
    })
    return <div className='d-flex gap-2 flex-wrap '>{manufacturersNames}</div>
  }

  document.title = 'Profile | Origins Direct'
  return (
    <React.Fragment>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>
      <div className='page-content'>
        <div className='container-fluid'>
          <div className='d-flex justify-content-between'>
            <h2 className='fs-3 fw-bold  mb-4'>Profile</h2>
            <div className='text-center d-flex gap-2 h-25'>
              {!editMode && (
                <Button
                  type='submit'
                  color='primary'
                  onClick={() =>
                    setChangePasswordMode((prevState) => !prevState)
                  }
                >
                  {changePasswordMode ? 'Back' : 'Change Password'}
                </Button>
              )}

              {!changePasswordMode && (
                <Button
                  type='submit'
                  color='primary'
                  onClick={() => setEditMode((prevState) => !prevState)}
                >
                  {user.role !== 'pharmacy'
                    ? editMode
                      ? 'Back'
                      : 'Edit Profile'
                    : editMode
                    ? 'Back'
                    : status === 0
                    ? 'Complete Profile'
                    : 'Edit Profile'}
                </Button>
              )}
            </div>
          </div>

          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <div className='d-flex'>
                    <div className='row row-cols-2 w-100'>
                      <div className='text-muted'>
                        {user?.role === 'admin' ? (
                          <>
                            <h4 className='mb-2'>{user.displayName}</h4>
                            <h6 className='mb-1'>Admin</h6>
                          </>
                        ) : user?.role === 'pharmacy' ? (
                          <>
                            <h4 className='mb-2'>{user.tradeName}</h4>
                            <h6 className='mb-1'>Pharmacy</h6>
                          </>
                        ) : user?.role === 'manufacturer' ? (
                          <>
                            <h4 className='mb-2'>{user.displayName}</h4>
                            <h6 className='mb-1'>Manufacturer</h6>
                          </>
                        ) : null}
                      </div>
                      <div className='text-muted'>
                        {user?.role === 'pharmacy' ? (
                          <>
                            <h4 className='mb-2'>{user.accountNo}</h4>
                            <h6 className='mb-1'>Account Number</h6>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ============================================================ */}
          {!changePasswordMode && !changePasswordMode && !editMode && (
            <>
              <Card>
                <CardBody>
                  {userLoading ? (
                    <div className='d-flex justify-content-center align-items-center '>
                      <Spinner color='primary' />
                    </div>
                  ) : (
                    <>
                      {!isAdmin && user?.role !== 'manufacturer' && (
                        <h4 style={{ color: '#626ED4' }} className='mt-2 mb-4'>
                          Pharmacy Information
                        </h4>
                      )}

                      <div className='row row-cols-1 row-cols-sm-2 '>
                        {user?.role === 'pharmacy' && (
                          <div className='form-group col '>
                            <Label className='form-label'>Legal Name</Label>
                            <p>{user.legalName}</p>
                          </div>
                        )}
                        {user?.role !== 'pharmacy' && (
                          <div className='form-group col '>
                            <Label className='form-label'>Full Name</Label>
                            <p>{user.displayName}</p>
                          </div>
                        )}
                        {user?.role === 'pharmacy' && (
                          <div className='form-group col'>
                            <Label className='form-label mt-2'>
                              Trade Name / Pharmacy Name
                            </Label>
                            <p>{user.tradeName}</p>
                          </div>
                        )}
                        {user?.role === 'pharmacy' && (
                          <>
                            <div className='form-group col '>
                              <Label className='form-label mt-2'>
                                Accreditation Number
                              </Label>
                              <p>
                                {user.accreditation
                                  ? user.accreditation
                                  : 'There is no accreditation'}
                              </p>
                            </div>
                          </>
                        )}
                        <div className='form-group col '>
                          <Label className='form-label mt-2'>Email</Label>
                          <p>{user.email}</p>
                        </div>

                        {user?.role === 'pharmacy' && (
                          <div className='form-group col '>
                            <Label className='form-label mt-2'>Address</Label>
                            <p>
                              {user.address
                                ? user.address
                                : 'There is no address'}
                            </p>
                          </div>
                        )}
                        {user?.role === 'pharmacy' && (
                          <div className='form-group col '>
                            <Label className='form-label mt-2'>
                              Telephone Number
                            </Label>
                            <p>
                              {user.telephone
                                ? user.telephone
                                : 'There is no Telephone Number'}
                            </p>
                          </div>
                        )}
                        {user?.role === 'pharmacy' && (
                          <div className='form-group col '>
                            <Label className='form-label mt-2'>
                              Fax Number
                            </Label>
                            <p>
                              {user.fax ? user.fax : 'There is no Fax Number'}
                            </p>
                          </div>
                        )}
                        {user?.role === 'pharmacy' && (
                          <>
                            <div className='form-group col '>
                              <Label className='form-label mt-2'>
                                Shipping Address
                              </Label>
                              <p>
                                {user.shippingAddress
                                  ? user.shippingAddress
                                  : 'There is no Shipping Address'}
                              </p>
                            </div>
                          </>
                        )}

                        {user?.role === 'pharmacy' && (
                          <>
                            <h4
                              style={{ color: '#626ED4' }}
                              className='mt-4 mb-4'
                            >
                              Contact Person / Pharmacy Owner Information
                            </h4>
                            <div></div>
                          </>
                        )}
                        {user?.role === 'pharmacy' && (
                          <>
                            <div className='form-group col '>
                              <Label className='form-label mt-2'>Name</Label>
                              <p>
                                {user.pharmacyOwnerName
                                  ? user.pharmacyOwnerName
                                  : 'There is no name'}
                              </p>
                            </div>
                            <div className='form-group col '>
                              <Label className='form-label mt-2'>
                                Telephone number
                              </Label>
                              <p>
                                {user.pharmacyOwnerTelephone
                                  ? user.pharmacyOwnerTelephone
                                  : 'Same as Pharmacy Phone'}
                              </p>
                            </div>
                            <div className='form-group col '>
                              <Label className='form-label mt-2'>Email</Label>
                              <p>
                                {user.pharmacyOwnerEmail
                                  ? user.pharmacyOwnerEmail
                                  : 'Same as Pharmacy Email'}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
              {user?.role === 'manufacturer' && (
                <Card>
                  <CardBody>
                    <div className='form-group col '>
                      <Label className='form-label'>Manufacturers</Label>
                      <div>{manufacturers()}</div>
                    </div>
                  </CardBody>
                </Card>
              )}
              {!isAdmin && user?.role !== 'manufacturer' && (
                <Card>
                  <CardBody>
                    <div className='row row-cols-1 row-cols-sm-2'>
                      <div className='form-group col '>
                        <Label className='form-label'>Systems</Label>
                        <h4 className='m-0'>{pharmacyModules}</h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </>
          )}

          {/* ============================================================ */}
          {editMode && (
            <EditUserProfileInfo
              user={user}
              isAdmin={isAdmin}
              editDisplayName={editDisplayName}
              setEditDisplayName={setEditDisplayName}
              editName={editName}
              setEditName={setEditName}
              editTradeName={editTradeName}
              setEditTradeName={setEditTradeName}
              accreditationNo={editAccreditationNo}
              setAccreditationNo={setAccreditationNo}
              selectedShippingAddress={selectedShippingAddress}
              setSelectedShippingAddress={setSelectedShippingAddress}
              editTelephone={editTelephone}
              setEditTelephone={setEditTelephone}
              editFax={editFax}
              setEditFax={setEditFax}
              editPharmacyOwnerName={editPharmacyOwnerName}
              setEditPharmacyOwnerName={setEditPharmacyOwnerName}
              ownerPhone={ownerPhone}
              setOwnerPhone={setOwnerPhone}
              ownerEmail={ownerEmail}
              setOwnerEmail={setOwnerEmail}
              isOwnerContactDifferent={isOwnerContactDifferent}
              handleOwnerContactChange={handleOwnerContactChange}
              handleEditSubmit={handleEditSubmit}
              onProfileUpdate={onProfileUpdate}
              setEditMode={setEditMode}
              setUserLoading={setUserLoading}
            />
          )}
          {/* ============================================================ */}

          {changePasswordMode && (
            <div>
              <h4 className='card-title mb-4'>Change Password</h4>
              <Card>
                <CardBody>
                  <form className='row row-cols-1 row-cols-sm-2'>
                    <div className='form-group col'>
                      <Label className='form-label mt-3'>
                        Current Password
                      </Label>
                      <input
                        name='currentPassword'
                        className='form-control'
                        placeholder='Enter current password'
                        type='password'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className='form-group col'>
                      <Label className='form-label mt-3'>New Password</Label>
                      <input
                        name='newPassword'
                        className='form-control'
                        placeholder='Enter new password'
                        type='password'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className='form-group col'>
                      <Label className='form-label mt-3'>
                        Confirm New Password
                      </Label>
                      <input
                        name='confirmPassword'
                        className='form-control'
                        placeholder='Confirm new password'
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div> </div>
                    <div className='text-center mt-4 d-flex justify-content-end gap-3 w-100 col-12'>
                      <Button
                        color=''
                        className='btn btn-outline-primary'
                        onClick={() => setChangePasswordMode(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='primary'
                        onClick={(e) => {
                          e.preventDefault()
                          handlePasswordSubmit()
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </div>
          )}
          {!isAdmin && user?.role !== 'manufacturer' && (
            <>
              {originsManagementModule && !changePasswordMode && !editMode && (
                <div>
                  <h4 className='card-title mb-4'>Suppliers</h4>
                  <Card>
                    <CardBody>
                      <DataTable
                        value={user?.accounts}
                        dataKey='supplierId'
                        removableSort
                        // loading={tableLoading}
                        selectionMode='single'
                        // onRowSelect={onRowSelect}
                        // selection={selectedMolecule}
                        // onSelectionChange={(e) => setSelectedMolecule(e.value)}
                        emptyMessage='No Suppliers found.'
                      >
                        <Column field='supplierName' header='Name' sortable />
                        <Column
                          field='customerAccountCode'
                          header='Account Code'
                          sortable
                        />
                      </DataTable>
                    </CardBody>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
