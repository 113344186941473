import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Card, CardBody, Label, Button, Spinner } from 'reactstrap'
import PlacesAutocomplete from '../shared/PlacesAutoComplete'
import { AuthenticationContext } from '../../Auth/authentication.context'
import { Checkbox } from 'primereact/checkbox'

const PharmacyProfileDetails = ({ user, reloadData, loading }) => {
  const [ownerPhone, setOwnerPhone] = useState(
    user?.pharmacy?.pharmacyOwnerTelephone || ''
  )
  const [ownerEmail, setOwnerEmail] = useState(
    user?.pharmacy?.pharmacyOwnerEmail || ''
  )
  const [editDisplayName, setEditDisplayName] = useState(
    user?.displayName || ''
  )
  const [editMode, setEditMode] = useState(false)
  const [editName, setEditName] = useState(user?.pharmacy?.legalName || '')
  const [editPharmacyName, setEditPharmacyName] = useState(
    user?.pharmacyName || ''
  )
  const [editTelephone, setEditTelephone] = useState(
    user?.pharmacy?.telephone || ''
  )
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [userLoading, setUserLoading] = useState(false)
  const [isOwnerContactDifferent, setIsOwnerContactDifferent] = useState(false)
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null)
  const [editTradeName, setEditTradeName] = useState(
    user?.pharmacy?.tradeName || ''
  )
  const [editPharmacyOwnerName, setEditPharmacyOwnerName] = useState(
    user?.pharmacy?.pharmacyOwnerName || ''
  )
  const [editFax, setEditFax] = useState(user?.pharmacy?.fax || '')
  const [isPharmacistInfoDifferent, setIsPharmacistInfoDifferent] =
    useState(false)
  const [editAccreditation, setEditAccreditation] = useState(
    user?.pharmacy?.accreditation || ''
  )

  const { onProfileUpdate, success } = useContext(AuthenticationContext)
  const [systemsEditData, setSystemsEditData] = useState([])

  const [originsDirectModule, setOriginsDirectModule] = useState(null)
  const [originsManagementModule, setOriginsManagementModule] = useState(null)
  const [systemsLoading, setSystemsLoading] = useState(false)
  const [systemError, setSystemError] = useState('')

  console.log('systemsEditData', systemsEditData)
  useEffect(() => {
    const systemsData =
      user?.pharmacyModules?.map((module) => module.name) || []
    setSystemsEditData(systemsData)

    const originsDirect = systemsData.includes('Origins Direct')
    setOriginsDirectModule(originsDirect)

    const originsManagement = systemsData.includes('Origins Management')
    setOriginsManagementModule(originsManagement)
  }, [user])

  const onSystemChange = (e, moduleName) => {
    const isChecked = e.checked

    // Prevent unchecking if it's the last checked system
    if (!isChecked && systemsEditData.length === 1) {
      setSystemError('At least one system must be selected')
      return
    }
    setSystemError('')

    if (moduleName === 'Origins Direct') {
      setOriginsDirectModule(isChecked)
    } else if (moduleName === 'Origins Management') {
      setOriginsManagementModule(isChecked)
    }

    const updatedSystemsEditData = isChecked 
      ? [...systemsEditData, moduleName]
      : systemsEditData.filter(name => name !== moduleName)

    setSystemsEditData(updatedSystemsEditData)
  }

  const handleEditSubmit = () => {
    // Create an object to store only the changed values
    const updatedFields = {}

    // Only add fields that have changed
    if (editDisplayName !== user?.displayName) {
      updatedFields.displayName = editDisplayName
    }

    if (editName !== user?.pharmacy?.legalName) {
      updatedFields.legalName = editName
    }

    if (editTradeName !== user?.pharmacy?.tradeName) {
      updatedFields.tradeName = editTradeName
    }

    if (editPharmacyName !== user?.pharmacyName) {
      updatedFields.pharmacyName = editPharmacyName
    }

    if (selectedAddress) {
      updatedFields.address = selectedAddress
    }

    if (editTelephone !== user?.pharmacy?.telephone) {
      updatedFields.telephone = editTelephone
    }

    if (editFax !== user?.pharmacy?.fax) {
      updatedFields.fax = editFax
    }

    if (selectedShippingAddress) {
      updatedFields.shippingAddress = selectedShippingAddress
    }

    if (editPharmacyOwnerName !== user?.pharmacy?.pharmacyOwnerName) {
      updatedFields.pharmacyOwnerName = editPharmacyOwnerName
    }

    if (editAccreditation !== user?.pharmacy?.accreditation) {
      updatedFields.accreditation = editAccreditation
    }

    // Handle owner contact information
    const updatedPharmacyOwnerTelephone = isOwnerContactDifferent
      ? ownerPhone
      : null

    const updatedPharmacyOwnerEmail = isOwnerContactDifferent
      ? ownerEmail
      : null

    if (
      updatedPharmacyOwnerTelephone !== user?.pharmacy?.pharmacyOwnerTelephone
    ) {
      updatedFields.pharmacyOwnerTelephone = updatedPharmacyOwnerTelephone
    }

    if (updatedPharmacyOwnerEmail !== user?.pharmacy?.pharmacyOwnerEmail) {
      updatedFields.pharmacyOwnerEmail = updatedPharmacyOwnerEmail
    }

    console.log('   user?.id', user?.id)

    // Only make the API call if there are actual changes
    if (Object.keys(updatedFields).length > 0) {
      onProfileUpdate(
        updatedFields.displayName,
        updatedFields.legalName,
        updatedFields.tradeName,
        editAccreditation,
        updatedFields.address,
        updatedFields.telephone,
        updatedFields.fax,
        updatedFields.shippingAddress,
        updatedFields.pharmacyOwnerName,
        updatedFields.pharmacyOwnerTelephone,
        updatedFields.pharmacyOwnerEmail,
        user?.id,
        systemsEditData
      )

      setEditMode(false)
      setUserLoading(true)
    } else {
      // No changes were made
      setEditMode(false)
    }
  }

  const handleSystemEditSubmit = () => {
    if (systemsEditData.length === 0) {
      setSystemError('Please select at least one system')
      return
    }
    setSystemError('')

    const updatedPharmacyOwnerTelephone = isOwnerContactDifferent
      ? ownerPhone
      : null

    const updatedPharmacyOwnerEmail = isOwnerContactDifferent
      ? ownerEmail
      : null

    onProfileUpdate(
      editDisplayName,
      editName,
      editTradeName,
      editAccreditation,
      selectedAddress,
      editTelephone,
      editFax,
      selectedShippingAddress,
      editPharmacyOwnerName,
      updatedPharmacyOwnerTelephone,
      updatedPharmacyOwnerEmail,
      user?.id,
      systemsEditData
    )
    setEditMode(false)
    setSystemsLoading(true)
  }
  const handleOwnerContactChange = (e) => {
    setIsOwnerContactDifferent(e.checked)
    if (!e.checked) {
      // If checkbox is unchecked, clear the owner phone and email
      setOwnerPhone('')
      setOwnerEmail('')
    }
  }

  const pharmacyModules = user?.pharmacyModules.map((module, index) => (
    <span
      className='badge bg-primary rounded-pill text-light py-2 px-3 fw-bold mx-1'
      key={index}
    >
      {module.name}
    </span>
  ))

  useEffect(() => {
    if (success === true) {
      reloadData()
      setUserLoading(false)
      setSystemsLoading(false)
    }
  }, [success])
  useEffect(() => {
    if (loading === false) {
      setUserLoading(false)
    }
  }, [loading])
  return (
    <>
      <div className='text-end mb-3 '>
        <Button
          type='submit'
          color='primary'
          onClick={() => setEditMode((prevState) => !prevState)}
        >
          {editMode ? 'Back' : 'Edit Pharmacy'}
        </Button>
      </div>

      <Row>
        <Col lg='12'>
          <Card>
            <CardBody>
              <div className='d-flex'>
                <div className='row row-cols-2 w-100'>
                  <div className='text-muted'>
                    <h4 className='mb-2'>{user?.pharmacy?.legalName}</h4>
                    <h6 className='mb-1'>Pharmacy</h6>
                  </div>
                  <div className='text-muted'>
                    <h4 className='mb-2'>{user?.accountNo}</h4>
                    <h6 className='mb-1'>Account Number</h6>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardBody>
          {userLoading ? (
            <div className='d-flex justify-content-center align-items-center '>
              <Spinner color='primary' />
            </div>
          ) : (
            <>
              {!editMode && (
                <div className='row row-cols-2'>
                  <div className='mb-3'>
                    <h4 style={{ color: '#626ED4' }} className='mt-2 mb-2'>
                      Pharmacy Information
                    </h4>
                  </div>
                  <div></div>

                  {/* 1. Legal Name */}
                  <div className='form-group col '>
                    <Label className='form-label'>Legal Name</Label>
                    <p>{user?.pharmacy?.legalName}</p>
                  </div>

                  {/* 2. Trade Name */}
                  <div className='form-group col'>
                    <Label className='form-label mt-2'>Trade Name</Label>
                    <p>{user?.pharmacy?.tradeName}</p>
                  </div>

                  {/* 3. Address */}
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Address</Label>
                    <p>
                      {user?.address ? user?.address : 'There is no address'}
                    </p>
                  </div>

                  {/* 4. Shipping Address */}
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Shipping Address</Label>
                    <p>
                      {user?.pharmacy?.shippingAddress
                        ? user?.pharmacy?.shippingAddress
                        : 'There is no shipping address'}
                    </p>
                  </div>

                  {/* 5. Telephone Number */}
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Telephone Number</Label>
                    <p>
                      {user?.pharmacy?.telephone
                        ? user?.pharmacy?.telephone
                        : 'There is no Telephone Number'}
                    </p>
                  </div>

                  {/* 6. Fax */}
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Fax</Label>
                    <p>
                      {user?.pharmacy?.fax
                        ? user?.pharmacy?.fax
                        : 'There is no fax'}
                    </p>
                  </div>

                  {/* 7. Email */}
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Email</Label>
                    <p>{user?.email}</p>
                  </div>

                  {/* 8. Accreditation */}
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Accreditation</Label>
                    <p>
                      {user?.pharmacy?.accreditation
                        ? user?.pharmacy?.accreditation
                        : 'There is no accreditation'}
                    </p>
                  </div>

                  {/* Contact Person Section */}
                  <div className='col-12 mt-5'>
                    <h4 style={{ color: '#626ED4' }}>
                      Contact Person / Pharmacy Owner Information
                    </h4>
                  </div>

                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Name</Label>
                    <p>
                      {user?.pharmacy?.pharmacyOwnerName
                        ? user?.pharmacy?.pharmacyOwnerName
                        : 'There is no name'}
                    </p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>
                      Pharmacy Owner Telephone
                    </Label>
                    <p>
                      {user?.pharmacy?.pharmacyOwnerTelephone
                        ? user?.pharmacy?.pharmacyOwnerTelephone
                        : 'There is no pharmacy owner telephone'}
                    </p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Email</Label>
                    <p>
                      {user?.pharmacy?.pharmacyOwnerEmail
                        ? user?.pharmacy?.pharmacyOwnerEmail
                        : 'There is no pharmacy owner email'}
                    </p>
                  </div>
                </div>
              )}
              {editMode && (
                <div>
                  <form className=''>
                    <h4 style={{ color: '#626ED4' }} className='mt-2 mb-2'>
                      Pharmacy Information
                    </h4>
                    <div className='row row-cols-1 row-cols-sm-2'>
                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Legal Name</Label>
                        <input
                          name='legalName'
                          className='form-control'
                          type='text'
                          placeholder={user?.pharmacy?.legalName}
                          value={editName}
                          onChange={(e) => setEditName(e.target.value)}
                        />
                      </div>

                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Trade Name</Label>
                        <input
                          name='tradeName'
                          className='form-control'
                          type='text'
                          placeholder={user?.pharmacy?.tradeName}
                          value={editTradeName}
                          onChange={(e) => setEditTradeName(e.target.value)}
                        />
                      </div>

                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Address</Label>
                        <input
                          name='tradeName'
                          className='form-control'
                          type='text'
                          placeholder={user.address}
                          disabled={true}
                        />
                      </div>

                      <div className='form-group col'>
                        <Label className='form-label mt-3'>
                          Shipping Address
                        </Label>
                        <PlacesAutocomplete
                          placeholder={user.shippingAddress}
                          setSelectedAddress={(e) =>
                            setSelectedShippingAddress(e)
                          }
                        />
                      </div>

                      <div className='form-group col'>
                        <Label className='form-label mt-3'>
                          Telephone Number
                        </Label>
                        <input
                          name='telephone'
                          className='form-control'
                          type='text'
                          placeholder={user?.pharmacy?.telephone}
                          value={editTelephone}
                          onChange={(e) => setEditTelephone(e.target.value)}
                        />
                      </div>

                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Fax Number</Label>
                        <input
                          name='fax'
                          className='form-control'
                          type='text'
                          placeholder={user?.pharmacy?.fax}
                          value={editFax}
                          onChange={(e) => setEditFax(e.target.value)}
                        />
                      </div>

                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Email</Label>
                        <input
                          name='UserEmail'
                          className='form-control'
                          placeholder={user.email}
                          type='text'
                          disabled={true}
                        />
                      </div>

                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Accreditation</Label>
                        <input
                          name='accreditation'
                          className='form-control'
                          type='text'
                          placeholder={
                            user?.pharmacy?.accreditation ||
                            'Enter accreditation'
                          }
                          value={editAccreditation}
                          onChange={(e) => setEditAccreditation(e.target.value)}
                        />
                      </div>
                    </div>

                    <>
                      <h4 style={{ color: '#626ED4' }} className='mt-4 mb-2'>
                        Contact Person / Pharmacy Owner Information
                      </h4>
                      <div></div>
                    </>

                    <div className='row'>
                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Name</Label>
                        <input
                          name='pharmacyOwnerName'
                          className='form-control'
                          type='text'
                          placeholder={
                            user?.pharmacy?.pharmacyOwnerName || 'Enter name'
                          }
                          value={editPharmacyOwnerName}
                          onChange={(e) =>
                            setEditPharmacyOwnerName(e.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='form-group col'>
                        <div className='d-flex align-items-center mt-4 mb-1'>
                          <Checkbox
                            inputId='originsDirect'
                            name='Origins Direct'
                            value='Origins Direct'
                            checked={isOwnerContactDifferent}
                            onChange={handleOwnerContactChange}
                          />
                          <label htmlFor='originsDirect' className='mx-2 mb-0'>
                            Phone and email are different
                          </label>
                        </div>
                      </div>
                    </div>

                    {isOwnerContactDifferent && (
                      <>
                        <div className='row row-cols-1 row-cols-sm-2'>
                          <div className='form-group col'>
                            <Label className='form-label mt-3'>
                              Telephone Number
                            </Label>
                            <input
                              name='pharmacyOwnerTelephone'
                              className='form-control'
                              type='text'
                              placeholder={
                                user?.pharmacyOwnerTelephone
                                  ? user?.pharmacyOwnerTelephone
                                  : 'Enter Telephone Number'
                              }
                              value={ownerPhone || ''}
                              onChange={(e) => setOwnerPhone(e.target.value)}
                            />
                          </div>

                          {/* --------------------------- */}
                          <div className='form-group col'>
                            <Label className='form-label mt-3'>Email</Label>
                            <input
                              name='pharmacyOwnerEmail'
                              className='form-control'
                              type='text'
                              placeholder={
                                user?.pharmacyOwnerEmail
                                  ? user?.pharmacyOwnerEmail
                                  : 'Enter Email'
                              }
                              value={ownerEmail || ''}
                              onChange={(e) => setOwnerEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className='text-center mt-4 d-flex justify-content-end gap-3 col-12'>
                      <Button
                        color=''
                        className='btn btn-outline-primary'
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='primary'
                        onClick={(e) => {
                          e.preventDefault()
                          handleEditSubmit()
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              )}
              <div className='col col-sm-6  col-lg-4 mt-3 '></div>
            </>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {systemsLoading ? (
            <div className='d-flex justify-content-center align-items-center '>
              <Spinner color='primary' />
            </div>
          ) : (
            <>
              {!editMode && (
                <div className='row row-cols-2'>
                  <div className='form-group col '>
                    <Label className='form-label'>Systems</Label>
                    <h4 className='m-0'>{pharmacyModules}</h4>
                  </div>
                </div>
              )}

              {editMode && (
                <div>
                  <h4 className='card-title mb-4'>Edit Systems</h4>
                  <form className='row row-cols-3'>
                    <div className='form-group col'>
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          inputId='originsDirect'
                          name='Origins Direct'
                          value='Origins Direct'
                          onChange={(e) => onSystemChange(e, 'Origins Direct')}
                          checked={originsDirectModule}
                        />
                        <label htmlFor='originsDirect' className='mx-2 mb-0'>
                          Origins Direct
                        </label>
                      </div>
                    </div>

                    <div className='form-group col'>
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          inputId='originsManagement'
                          name='Origins Management'
                          value='Origins Management'
                          onChange={(e) => onSystemChange(e, 'Origins Management')}
                          checked={originsManagementModule}
                        />
                        <label htmlFor='originsManagement' className='mx-2 mb-0'>
                          Origins Management
                        </label>
                      </div>
                    </div>

                    {/* Add error message display */}
                    {systemError && (
                      <div className='col-12'>
                        <small className='text-danger'>{systemError}</small>
                      </div>
                    )}

                    <div className='text-center mt-4 d-flex justify-content-end gap-3 col-12'>
                      <Button
                        color=''
                        className='btn btn-outline-primary'
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='primary'
                        onClick={(e) => {
                          e.preventDefault()
                          handleSystemEditSubmit()
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default PharmacyProfileDetails
