import React, { useEffect, useState } from 'react'
import { Card, Button } from 'reactstrap'

import server from '../../server'
import { useParams, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import originsDirect from '../../assets/images/ORIGINS-DIRECT.png'
const ManufacturerResponse = () => {
  const { token } = useParams()
  const navigate = useNavigate()



  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [accountCode, setAccountCode] = useState(null)
  const [saved, setSaved] = useState(false)


  const getData = () => {
    setLoading(true)
    server
      .get(`/manufacturerPharmacyCode/getPharmacyDetailsByCodeId/${token}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setLoading(false)
        setData(response.data)
        setAccountCode(response.data.manufacturerCode)
        setSaved(response.data.manufacturerCode !== null ? true : false)
        if (response.data.status !== 'pending') {
          navigate('/already-responded')
        }
      })
      .catch((error) => {
        console.error('Error fetching Manufacturer Data:', error)
        setLoading(false)
      })
  }
  const sendData = (requestBody) => {
    setLoading(true)
    server
      .post(
        `/manufacturerPharmacyCode/handlePharmacyRequest/${token}`,
        requestBody, // Request body here
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      )
      .then((response) => {

        setLoading(false) // Reset loading state on success

        if (response.data.status === 'declined') {
          navigate('/pharmacy-account-rejected')
        } else if (response.data.status === 'active') {
          navigate('/pharmacy-account-approved')
        }
      })
      .catch((error) => {
        console.error('Error sending Manufacturer Data:', error)
        setLoading(false) // Reset loading state on error
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const handleAccept = () => {
    const requestBody = {
      manufacturer_code: accountCode,
      status: 'active',
    }

    sendData(requestBody) // Call the sendData function with the request body
  }

  const handleReject = () => {
    const requestBody = {
      manufacturer_code: accountCode,
      status: 'declined',
    }
    sendData(requestBody) // Call the sendData function with the request body
  }
  return (
    <>
      <div className='min-vh-100 d-flex justify-content-center align-items-center'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '70vh' }}
          >
            <Spinner color='primary' />
          </div>
        ) : (
          <div
            className=' justify-content-center  w-full'
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div className='w-full mx-auto flex justify-center'>
              <img
                src={originsDirect}
                alt='Origins Direct'
                height='60'
                className='mx-auto'
              />
            </div>
            <Card className='w-100 m-3' style={{ maxWidth: '1300px' }}>
              <main className=' p-4'>
                {/* <Approval /> */}
                <section>
                  <h1 className='h4 text-center mb-3 mt-4'>
                    Account Activation Request for {data?.pharmacyName}
                  </h1>
                  <div style={{ margin: '30px auto', width: 'fit-content' }}>
                    <p>
                      A new pharmacy is requesting the activation of their
                      account with your company. Below, please find the relevant
                      account information:
                    </p>
                    <p>
                      <strong>Pharmacy Name:</strong> {data?.pharmacyName}{' '}
                      <br />
                      <strong>Account Number:</strong>{' '}
                      {data.manufacturerCode || 'Account number not available'}{' '}
                      <br />
                      <strong>Pharmacy Address:</strong>
                      {data?.address || 'Address not available'}
                      <br />
                      <strong>Pharmacy Email: </strong>
                      {data?.email || 'Email not available'}
                      <br />
                      <strong>Pharmacy Telephone: </strong>
                      {data?.telephone || 'Telephone not available'}
                      <br />
                    </p>
                  </div>
                </section>

                <div
                  className='form-group col'
                  style={{ maxWidth: '300px', margin: '0 auto' }}
                >
                  <label className='form-label mt-3'>Account no.</label>
                  <div className='d-flex align-items-center'>
                    <input
                      className='form-control'
                      type='text'
                      value={accountCode}
                      onChange={(e) => setAccountCode(e.target.value)}
                      disabled={saved}
                    />
                    <Button
                      type='submit'
                      color='primary'
                      onClick={() => accountCode && setSaved(!saved)}
                      disabled={accountCode === '' || accountCode === null}
                    >
                      {saved ? 'Edit' : 'Save'}
                    </Button>
                  </div>
                </div>

                <div className='text-center mt-5 d-flex justify-content-center gap-3 col-12'>
                  <Button
                    className='btn btn-outline-primary'
                    size='lg'
                    onClick={handleReject}
                  >
                    Decline
                  </Button>
                  <Button
                    color='primary'
                    size='lg'
                    onClick={handleAccept}
                    disabled={!saved}
                  >
                    Accept
                  </Button>
                </div>
              </main>
            </Card>
          </div>
        )}
      </div>
    </>
  )
}

export default ManufacturerResponse
