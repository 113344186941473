import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import DeleteModal from '../shared/DeleteModal'
import server from '../../server'
import { useNavigate } from 'react-router-dom'
import MainContentLayout from '../Layout/MainContentLayout'
import { InputFieldTemplate, DropDownTemplate } from '../shared/FilterTemplates'
import { FilterMatchMode, FilterOperator } from 'primereact/api'

export default function PharmaciesTable() {
  const [pharmacies, setPharmacies] = useState([])
  const [selectedPharmacy, setSelectedPharmacy] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [singleUserData, setSingleUserData] = useState({})
  const [csvLoading, setcsvLoading] = useState(false)

  const [lazyState, setLazyState] = useState({
    rows: 10,
    page: 0,
    first: 0,
  })

  const navigate = useNavigate()
  const dt = useRef()
  const initLazyState = () => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: '',
      sortOrder: '',
      filters: {
        // global: { value: 'null', matchMode: FilterMatchMode.CONTAINS },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        displayName: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        pharmacyName: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        pharmacyModule: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
    }))
  }

  const displayName = lazyState?.filters?.displayName?.constraints[0]?.value
  const email = lazyState?.filters?.email?.constraints[0]?.value
  const pharmacyName = lazyState?.filters?.pharmacyName?.constraints[0]?.value
  const pharmacyModule =
    lazyState?.filters?.pharmacyModule?.constraints[0]?.value

  let requestParams =
    `?page=${lazyState?.page ? lazyState.page + 1 : 1}` +
    `&limit=${lazyState?.rows}` +
    '&role=pharmacy' +
    `${email ? '&email=' + email : ''}` +
    `${pharmacyName ? '&pharmacyName=' + pharmacyName : ''}` +
    `${displayName ? '&displayName=' + displayName : ''}` +
    `${pharmacyModule ? '&pharmacyModule=' + pharmacyModule : ''}` +
    `${globalFilterValue ? '&q=' + globalFilterValue : ''}`

  const getData = () => {
    setTableLoading(true)

    // const displayName = lazyState.filters.displayName.constraints[0].value;

    server
      .get(`/users/get-users/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setPharmacies(response.data.users)
        setTotalRecords(response.data.totalCount)
        setTableLoading(false)
      })
      .catch((error) => {
        setTableLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    getData()
  }, [lazyState, globalFilterValue])

  useEffect(() => initLazyState(), [])

  const onPage = (event) => {
    setLazyState((prevState) => ({
      ...prevState,
      rows: event.rows,
      page: event.page,
      first: event.first,
    }))
  }

  const onSort = (event) => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    }))
  }

  const onFilter = (event) => {
    const updatedFilters = { ...lazyState.filters, ...event.filters }
    setLazyState((prevState) => ({
      ...prevState,
      filters: updatedFilters,
    }))
  }
  const NameFilterElement = (options) => {
    return (
      <InputFieldTemplate
        state={options.value}
        stateHandler={(e) => options.filterCallback(e, options.index)}
        placeholder='Name'
      />
    )
  }

  const emailFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      placeholder='Email'
    />
  )
  const PharmacyNameFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => {
        options.filterCallback(e, options.index)
      }}
      placeholder='Pharmacy Name'
    />
  )
  const ModuleFilterElement = (options) => (
    <DropDownTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e.value, options.index)}
      placeholder='Select module'
      options={[
        { name: 'Origins Management', value: 'Origins Management' },
        { name: 'Origins Direct', value: 'Origins Direct' },
      ]}
    />
  )

  useEffect(() => {
    checkClearBtn()
  }, [lazyState, globalFilterValue])

  const checkClearBtn = () => {
    if (globalFilterValue) {
      setClearBtnDisabled(false)
      return
    }
    if (lazyState.sortField || lazyState.sortOrder) {
      setClearBtnDisabled(false)
      return
    }
    for (const key in lazyState?.filters) {
      if (key === 'global') {
        continue
      } else if (
        lazyState?.filters[key]?.constraints &&
        (lazyState.filters[key].constraints[0]?.value ||
          lazyState.filters[key]?.constraints[1]?.value)
      ) {
        setClearBtnDisabled(false)
        return
      }
    }
    setClearBtnDisabled(true)
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    setGlobalFilterValue(value)
  }
  const clearFilter = () => {
    setGlobalFilterValue('')
    initLazyState()
  }

  const handleDeleteUser = () => {
    setLoading(true)
    server
      .delete(`/users/${singleUserData?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setLoading(false)
        setDeleteVisible(false)
        getData()
      })
      .catch((error) => {
        setLoading(false)

      })
  }

  const onRowSelect = (event) => {
    const pharmacy = pharmacies.find((user) => user.id === event.data.id)
    navigate(`${event.data.pharmacyId}`, { state: pharmacy })
  }
  const SystemsBody = (rowData) => {
    let systemNames = rowData?.pharmacyModules?.map((itm, index) => {
      return (
        <h4 className='m-0' key={index}>
          <span className='badge bg-primary  rounded-pill text-light py-2 px-3 fw-bold '>
            {itm?.name}
          </span>
        </h4>
      )
    })
    return <div className='d-flex gap-2 flex-wrap '>{systemNames}</div>
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex justify-content-center gap-2'>
          <Button
            icon='pi pi-trash'
            rounded
            outlined
            severity='danger'
            onClick={() => {
              setDeleteVisible(true)
              setSingleUserData(rowData)
            }}
          />
        </div>
      </React.Fragment>
    )
  }
  const hideDeleteUser = () => {
    setDeleteVisible(false)
  }

  const deleteFooter = (
    <React.Fragment>
      <Button label='No' icon='pi pi-times' outlined onClick={hideDeleteUser} />
      <Button
        label='Yes'
        icon='pi pi-check'
        severity='danger'
        loading={loading}
        onClick={handleDeleteUser}
      />
    </React.Fragment>
  )

  const topLeftToolbarTemplate = () => {
    return (
      <Button
        type='button'
        icon='pi pi-filter-slash'
        label='Clear'
        outlined
        onClick={clearFilter}
        disabled={clearBtnDisabled}
      />
    )
  }

  const topRightToolbarTemplate = () => {
    return (
      <div>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            value={globalFilterValue ? globalFilterValue : ''}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </span>
      </div>
    )
  }

  const exportCSV = () => {
    setcsvLoading(true)
    server
      .get(`/users/export/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blobData)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'manufacturers users.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        setcsvLoading(false)
      })
      .catch((error) => {
        setTableLoading(false)
      
      })
  }

  return (
    <React.Fragment>
      <MainContentLayout
        title='Pharmacies'
        dt={exportCSV}
        handleExport={true}
        loading={csvLoading}
      >
        <Toolbar
          className='mb-2'
          left={topLeftToolbarTemplate}
          right={topRightToolbarTemplate}
        />
        <DataTable
          ref={dt}
          value={pharmacies}
          paginator
          showGridlines
          removableSort
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 25, 50]}
          loading={tableLoading}
          dataKey='id'
          globalFilterFields={[
            'displayName',
            'email',
            'pharmacyName',
            'pharmacyModule',
          ]}
          selectionMode='single'
          filters={lazyState?.filters}
          onRowSelect={onRowSelect}
          selection={selectedPharmacy}
          onSelectionChange={(e) => setSelectedPharmacy(e.value)}
          emptyMessage='No pharmacies found.'
          sortField={lazyState?.sortField}
          sortOrder={lazyState?.sortOrder}
          rows={lazyState?.rows}
          first={lazyState?.first}
          onPage={onPage}
          onSort={onSort}
          onFilter={onFilter}
          lazy
        >
          <Column
            field='pharmacyName'
            header='Pharmacy Name'
            filter
            sortable
            filterElement={PharmacyNameFilterElement}
            style={{ minWidth: '12rem' }}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          />

          <Column
            field='accountNo'
            header='Account Number'
            filter
            sortable
            filterElement={NameFilterElement}
            style={{ minWidth: '12rem' }}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          />
          <Column
            field='email'
            header='Email'
            sortable
            filter
            filterElement={emailFilterElement}
            style={{ minWidth: '12rem' }}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          />

          <Column
            field='pharmacyModule'
            filter
            // sortable
            filterElement={ModuleFilterElement}
            header='Systems'
            style={{ minWidth: '16rem' }}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            body={SystemsBody}
          />

          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: '12rem' }}
          />
        </DataTable>
        <DeleteModal
          state={deleteVisible}
          footer={deleteFooter}
          onHide={hideDeleteUser}
          deletedItem={true}
          name={singleUserData?.displayName}
        />
      </MainContentLayout>
    </React.Fragment>
  )
}
