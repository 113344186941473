import React from 'react'
import { Container, Row } from 'reactstrap'

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='footer'>
        <Container fluid={true}>
          <Row>
            <div className='col-12'>
              Copyright {new Date().getFullYear()} © Origins Systems
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
