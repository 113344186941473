import React from 'react'

const StatusBadge = ({ status }) => {
  const getStatusData = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return { color: '#DDC2FF', status: 'Pending...', textColor: '#9333EA' } // Light Purple for "Pending"
      case 'available':
        return { color: '#C7D0FA', status: 'Available', textColor: '#626ED4' } // Blue-Purple for "Available"
      case 'active':
        return { color: '#D1E9C8', status: 'Active', textColor: '#2FB500' } // Green for "Active"
      default:
        return {
          color: '#D5DAE1',
          status: 'Coming soon..',
          textColor: '#5B626B',
        } // Default grey color
    }
  }

  const { color, status: displayStatus, textColor } = getStatusData(status)

  return (
    <div>
      <span
        className=' px-3 py-1'
        style={{
          backgroundColor: color,
          color: textColor,
          borderRadius: '35px',
          padding: '0px 0px',
        }}
      >
        {displayStatus}
      </span>
    </div>
  )
}

export default StatusBadge
