import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Label, Card, CardBody } from 'reactstrap'
import PlacesAutocomplete from '../shared/PlacesAutoComplete'
import { Checkbox } from 'primereact/checkbox'

const EditUserProfileInfo = ({
  user,
  isAdmin,
  handleEditSubmit,
  selectedShippingAddress,
  setSelectedShippingAddress,
  setEditMode,
  setUserLoading,
  onProfileUpdate,
}) => {
  const [isOwnerContactDifferent, setIsOwnerContactDifferent] = useState(
    user?.pharmacyOwnerEmail || user?.pharmacyOwnerTelephone ? false : true
  )

  const handleOwnerContactChange = (e) => {
    setIsOwnerContactDifferent(e.checked)
    if (e.checked) {
      formik.setFieldValue('pharmacyOwnerTelephone', '')
      formik.setFieldValue('pharmacyOwnerEmail', '')
    } else {
      formik.setFieldValue(
        'pharmacyOwnerTelephone',
        user?.pharmacyOwnerTelephone
      )
      formik.setFieldValue('pharmacyOwnerEmail', user?.pharmacyOwnerEmail)
    }
  }


  // Yup Validation Schema
  const validationSchema = Yup.object({
    ...(user?.role !== 'pharmacy' && {
      fullName: Yup.string().required('Full Name is required'),
    }),
    ...(user?.role === 'pharmacy' && {
      legalName: Yup.string().required('Legal Name is required'),
      tradeName: Yup.string().required('Trade Name is required'),
      accreditation: Yup.number(),
      pharmacyOwnerName: Yup.string().required('Owner Name is required'),
      shippingAddress: Yup.string().required('ShippingAddress is required'),

      ...(!isOwnerContactDifferent && {
        pharmacyOwnerTelephone: Yup.string().required(
          'Contact Person Telephone is required'
        ),
        pharmacyOwnerEmail: Yup.string()
          .email('Invalid email format')
          .required('Contact Person Email is required'),
      }),
      telephone: Yup.string().required('Telephone is required'),
      fax: Yup.string().required('Fax is required'),
    }),
  })

  // Formik configuration
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: user?.displayName || '',
      legalName: user.legalName || '',
      tradeName: user?.tradeName || '',
      accreditation: user?.accreditation || '',
      pharmacyOwnerName: user?.pharmacyOwnerName || '',
      pharmacyOwnerTelephone: user?.pharmacyOwnerTelephone || '',
      pharmacyOwnerEmail: user?.pharmacyOwnerEmail || '',
      telephone: user?.telephone || '',
      fax: user?.fax || '',
      shippingAddress: user?.shippingAddress || '',
      selectedAddress: null,
    },
    validationSchema,
    onSubmit: (values) => {
      onProfileUpdate(
        values.fullName,
        values.legalName,
        values.tradeName,
        values.accreditation,
        values.shippingAddress,
        values.telephone,
        values.fax,
        values.shippingAddress,
        values.pharmacyOwnerName,
        values.pharmacyOwnerTelephone,
        values.pharmacyOwnerEmail
      )

      // Update the UI state
      setEditMode(false)
      setUserLoading(true)
    },
  })

  return (
    <div>
      <h4 className='card-title mb-4'>Edit Profile</h4>
      <Card>
        <CardBody>
          <form onSubmit={formik.handleSubmit}>
            {!isAdmin && user?.role !== 'manufacturer' && (
              <h4 style={{ color: '#626ED4' }} className='mt-2 mb-2'>
                Pharmacy Information
              </h4>
            )}
            <div className='row row-cols-1 row-cols-sm-2'>
              {user?.role !== 'pharmacy' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Full Name</Label>
                  <input
                    name='fullName'
                    className='form-control'
                    type='text'
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <small className='text-danger'>
                      {formik.errors.fullName}
                    </small>
                  )}
                </div>
              )}
              {!isAdmin && user?.role !== 'manufacturer' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Legal Name</Label>
                  <input
                    name='legalName'
                    className='form-control'
                    type='text'
                    value={formik.values.legalName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.legalName && formik.errors.legalName && (
                    <small className='text-danger'>
                      {formik.errors.legalName}
                    </small>
                  )}
                </div>
              )}
              {!isAdmin && user?.role !== 'manufacturer' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Trade Name</Label>
                  <input
                    name='tradeName'
                    className='form-control'
                    type='text'
                    value={formik.values.tradeName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.tradeName && formik.errors.tradeName && (
                    <small className='text-danger'>
                      {formik.errors.tradeName}
                    </small>
                  )}
                </div>
              )}
              {!isAdmin && user?.role !== 'manufacturer' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Accreditation</Label>
                  <input
                    name='accreditation'
                    className='form-control'
                    type='number'
                    value={formik.values.accreditation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.accreditation &&
                    formik.errors.accreditation && (
                      <small className='text-danger'>
                        {formik.errors.accreditation}
                      </small>
                    )}
                </div>
              )}
              <div className='form-group col'>
                <Label className='form-label mt-3'>Email</Label>
                <input
                  name='email'
                  className='form-control'
                  type='text'
                  value={user.email}
                  disabled
                />
              </div>

              {!isAdmin && user?.role !== 'manufacturer' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Address</Label>
                  <input
                    name='tradeName'
                    className='form-control'
                    type='text'
                    placeholder={user.address}
                    disabled={true}
                  />
                </div>
              )}
              {!isAdmin && user?.role !== 'manufacturer' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Shipping Address</Label>
                  <PlacesAutocomplete
                    value={formik.values.shippingAddress}
                    placeholder={selectedShippingAddress}
                    setSelectedAddress={(e) => setSelectedShippingAddress(e)}
                  />
                </div>
              )}
              {!isAdmin && user?.role !== 'manufacturer' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Telephone</Label>
                  <input
                    name='telephone'
                    className='form-control'
                    type='text'
                    value={formik.values.telephone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.telephone && formik.errors.telephone && (
                    <small className='text-danger'>
                      {formik.errors.telephone}
                    </small>
                  )}
                </div>
              )}
              {!isAdmin && user?.role !== 'manufacturer' && (
                <div className='form-group col'>
                  <Label className='form-label mt-3'>Fax</Label>
                  <input
                    name='fax'
                    className='form-control'
                    type='text'
                    value={formik.values.fax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.fax && formik.errors.fax && (
                    <small className='text-danger'>{formik.errors.fax}</small>
                  )}
                </div>
              )}
            </div>
            {!isAdmin && user?.role !== 'manufacturer' && (
              <>
                <h4 style={{ color: '#626ED4' }} className='mt-4 mb-2'>
                  Contact Person / Pharmacy Owner Information
                </h4>
                <div className='row'>
                  <div className='form-group col'>
                    <Label className='form-label mt-3'>Name</Label>
                    <input
                      name='pharmacyOwnerName'
                      className='form-control'
                      type='text'
                      value={formik.values.pharmacyOwnerName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.pharmacyOwnerName &&
                      formik.errors.pharmacyOwnerName && (
                        <small className='text-danger'>
                          {formik.errors.pharmacyOwnerName}
                        </small>
                      )}
                  </div>

                  <div className='row'>
                    <div className='form-group col'>
                      <div className='d-flex align-items-center mt-4 mb-1'>
                        <Checkbox
                          inputId='originsDirect'
                          name='Origins Direct'
                          value='Origins Direct'
                          // onChange={(e) =>
                          //   setIsPharmacistInfoDifferent(e.checked)
                          // }
                          // checked={isPharmacistInfoDifferent}
                          checked={isOwnerContactDifferent}
                          onChange={handleOwnerContactChange}
                        />
                        <label htmlFor='originsDirect' className='mx-2 mb-0'>
                          Phone and Email are the same
                        </label>
                      </div>
                    </div>
                  </div>

                  {!isOwnerContactDifferent && (
                    <>
                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Telephone</Label>
                        <input
                          name='pharmacyOwnerTelephone'
                          className='form-control'
                          type='text'
                          value={formik.values.pharmacyOwnerTelephone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.pharmacyOwnerTelephone &&
                          formik.errors.pharmacyOwnerTelephone && (
                            <small className='text-danger'>
                              {formik.errors.pharmacyOwnerTelephone}
                            </small>
                          )}
                      </div>
                      <div className='form-group col'>
                        <Label className='form-label mt-3'>Email</Label>
                        <input
                          name='pharmacyOwnerEmail'
                          className='form-control'
                          type='text'
                          value={formik.values.pharmacyOwnerEmail}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.pharmacyOwnerEmail &&
                          formik.errors.pharmacyOwnerEmail && (
                            <small className='text-danger'>
                              {formik.errors.pharmacyOwnerEmail}
                            </small>
                          )}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <div className='text-center mt-4 d-flex justify-content-end gap-3 col-12'>
              <Button
                color=''
                className='btn btn-outline-primary'
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
              <Button type='submit' color='primary'>
                Save
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  )
}

export default EditUserProfileInfo
