import React, { useContext, useEffect, useState } from 'react'
import LineChart from './LineChart'
import { AuthenticationContext } from '../../Auth/authentication.context'
import { DateFilterTemplate } from '../shared/FilterTemplates'
import { formatCurrency, formatDate } from '../shared/utils'

import { useNavigate } from 'react-router-dom'

import server from '../../server'
import { Container, Row, Col, Card, CardBody, Spinner } from 'reactstrap'

// import images
import servicesIcon1 from '../../assets/images/services-icon/01.png'
import servicesIcon4 from '../../assets/images/services-icon/04.png'

import DashboardCards from './DashboardCards'

const ManufacturerDashboard = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const { setIsLoading } = useContext(AuthenticationContext)
  const currentDate = new Date()
  const previousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1
  )
  const [date, setDate] = useState([previousMonth, currentDate])
  const [data, setData] = useState(null)

  const titles = ['Total Orders Amount', '# of Orders']
  const icons = [servicesIcon1, servicesIcon4]

  useEffect(() => {
    if (date) {
      if (date[0] !== null && date[1] !== null) {
      }
    }
  }, [date])

  let startDate = formatDate(date[0])
  let endDate = formatDate(date[1])

  useEffect(() => {


    server
      .get(
        `/dashboard/manufacturer?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false)
        setLoading(false)

        setData(response.data)
      })
      .catch((e) => {
        if (e.response.status === 401) {

        }
        setIsLoading(false)
        setLoading(false)
      })
  }, [startDate, endDate])

  document.title = 'Dashboard | Origins Direct'
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div className='page-title-box text-center text-md-start  '>
            <Row className='align-items-center '>
              <Col md={7}>
                <h6 className='page-title'>Dashboard</h6>
                <ol className='breadcrumb m-0'>
                  <li
                    className='breadcrumb-item active'
                    style={{ width: '100%' }}
                  >
                    Welcome to Origins Direct
                  </li>
                </ol>
              </Col>

              <Col md='5'>
                <div className=' d-flex gap-3 align-items-center justify-content-end'>
                  <h6 style={{ minWidth: '80px' }}>Date range:</h6>

                  <DateFilterTemplate
                    state={date}
                    stateHandler={(e) => setDate(e.value)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {loading ? (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ height: '70vh' }}
            >
              <Spinner color='primary' />
            </div>
          ) : (
            <>
              <Row>
                {titles.map((title, index) => (
                  <Col key={index} xl={6} md={6}>
                    <Card className='mini-stat bg-primary text-white'>
                      <CardBody style={{ height: '120px' }}>
                        <DashboardCards
                          icon={icons[index]}
                          title={title}
                          value={
                            index !== 1
                              ? data?.dashboard?.totalOrdersAmount
                              : data?.totalNumberOfOrders
                          }
                        />
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <h6 className='card-title mb-4'>Last Six Months</h6>
                    </CardBody>
                    <LineChart role='manufacturer' />
                  </Card>
                </Col>
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <h2 className='card-title mb-4'>Latest Orders</h2>
                      <div className='wid-peity mb-4'>
                        <div className='row'>
                          {data?.latestOrders?.map((item, idx) => (
                            <React.Fragment key={idx}>
                              <div>
                                <h6>Order #{item?.supplierInvoiceNo}</h6>
                                <p className='text-muted mb-4'>
                                  Total:{' '}
                                  {formatCurrency(item?.totalInvoiceAmount)} |
                                  PA: {formatCurrency(item?.totalInvoicePa)}
                                </p>
                              </div>
                            </React.Fragment>
                          ))}
                          <button
                            onClick={() =>
                              navigate('/manufacturer/manufacturerOrders')
                            }
                            className='text-center'
                            style={{
                              backgroundColor: 'transparent',
                              border: '0',
                              boxShadow: 'none',
                              opacity: '0.7',
                              fontSize: '12px',
                            }}
                          >
                            View All
                          </button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManufacturerDashboard
